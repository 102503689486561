import React, { Component } from 'react';

import FormSignUp from '../forms/AuthSignUp';

class AuthSignUp extends Component {
	render() {
		return (
			<div className="modal fade auth-modal" id="signUpModal" tabIndex="-1" role="dialog">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header d-block d-md-none">
							<h4 className="modal-title">Create Account</h4>
						</div>
						<div className="modal-body p-0">
							<div className="row no-gutters">
								<div className="col-12 col-md-6 d-none d-md-block p-4 auth-modal-with-bg">&nbsp;</div>
								<div className="col-12 col-md-6 p-4">
									<h4 className="mb-0 font-weight-bolder">SIGN UP</h4>
									<span className="d-block mb-3">
										to start sharing your trips &amp; rating your favorite cities!
									</span>
									<FormSignUp />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AuthSignUp;
