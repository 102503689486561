import React, { Component } from 'react';

class VerifyEmail extends Component {
	render() {
		return (
			<div id="VerifyEmail" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h6 className="modal-title">
								Please Verify Your Email Address!
							</h6>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<i className="fas fa-envelope-open-text text-danger" /> Oops! Only user with verified
								email address are allowed to leave reviews. If you think you are already verified,
								please refresh the page and try again.
							</p>

							<p>
								Check your email inbox to verify your email or you can resend your Verification Link in
								Account Settings page.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default VerifyEmail;
