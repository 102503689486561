import React, { Component } from 'react';

class HelpFAQs extends Component {
	render() {
		const { faq, index } = this.props;

		return (
			<div className="accordion" id={`FAQ-${index}`}>
				<div className="card">
					<div className="card-header" id={`FAQHeading-${index}`}>
						<h5>
							<button
								className="btn btn-link"
								type="button"
								data-toggle="collapse"
								data-target={`#collapse${index}`}
								aria-expanded="false"
								aria-controls={`collapse${index}`}
							>
								Q: {faq.question}
							</button>
						</h5>
					</div>

					<div
						id={`collapse${index}`}
						className="collapse"
						aria-labelledby={`FAQHeading-${index}`}
						data-parent={`#FAQ-${index}`}
					>
						<div className="card-body">
							<b>Answer:</b>
							<div dangerouslySetInnerHTML={{ __html: faq.answer }} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HelpFAQs;
