import React, { Component } from 'react';

class StarRatingDisplay extends Component {
	render() {
		const { rating, name } = this.props;

		let full = parseInt(rating);
		let half = parseFloat((rating % 1).toFixed(2));

		let star = Array.apply(null, { length: 5 }).map(function(num, idx) {
			if (full > 0) {
				full--;
				return <i key={`star-${name}-${idx}`} className="fas fa-star" />;
			} else if (half > 0) {
				half = 0;
				return <i key={`star-${name}-${idx}`} className="fas fa-star-half-alt" />;
			}
			return <i key={`star-${name}-${idx}`} className="far fa-star" />;
		});

		return <span>{star}</span>;
	}
}

export default StarRatingDisplay;
