import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import config from '../../utils/config';
import DefaultAvatar from '../../assets/images/user.png';
import { useForm } from '../../utils/hooks';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

const SettingsProfile = ({ user, handleUpdate }) => {
	const isLoading = useSelector((state) => state.auth.profileLoading);
	const hasErrors = useSelector((state) => state.auth.profileError);

	const handleSend = () => {
		setLoading(true);
		values.birth_date = birthDate;
		values.image = image;

		if (image && image.size > 1024000) {
			setLoading(false);
			setError('The image you are trying to use as avatar is too large. Please limit the image size to 1MB');
			window.scrollBy({
				top: -2500,
				left: 0,
				behavior: 'smooth'
			});
			return;
		}

		handleUpdate('profiles', values);
	};

	const handleImageChange = (event) => {
		setImage(event.target.files[0]);
	};

	const [ birthDate, setBirthDate ] = useState(user.birth_date ? user.birth_date : '');
	const [ image, setImage ] = useState();
	const { values, errMsg, handleChange, handleSubmit } = useForm(
		{
			biography: user.biography || '',
			firstname: user.firstname || '',
			lastname: user.lastname || '',
			gender: user.gender || '',
			website: user.website || ''
		},
		handleSend,
		false
	);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(false);
				setError(hasErrors);
				window.scrollBy({
					top: -2500,
					left: 0,
					behavior: 'smooth'
				});
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
				window.scrollBy({
					top: -2500,
					left: 0,
					behavior: 'smooth'
				});
			}

			setLoading(isLoading);
		},
		[ isLoading, errMsg, hasErrors ]
	);

	return (
		<div className="card-body">
			{error && <Message type="alert-danger" message={error} />}
			Please provide some information about yourself. You have control over what others can see on your profile
			because all fields below are optional.
			<br />
			<br />
			The <strong>First Name</strong> and <strong>Last Name</strong> you provide will be displayed along side
			reviews you make on the site. The <strong>Gender</strong> and <strong>Birth Date</strong> will not be
			displayed on your profile and will only be used for statistical purposes.
			<form id="profiles" className="settings-form" onSubmit={handleSubmit}>
				<div className="form-row">
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label htmlFor="firstname">First Name</label>
							<input
								type="text"
								className="form-control"
								id="firstname"
								name="firstname"
								value={values.firstname}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label htmlFor="lastname">Last Name</label>
							<input
								type="text"
								className="form-control"
								id="lastname"
								name="lastname"
								value={values.lastname}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col">
						<div className="form-group">
							<label htmlFor="biography">Biography</label>
							<textarea
								className="form-control"
								id="biography"
								name="biography"
								rows="3"
								value={values.biography}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col-12 col-sm-6 col-lg-4">
						<div className="form-group">
							<label htmlFor="website">Website</label>
							<input
								type="text"
								className="form-control"
								id="website"
								name="website"
								value={values.website}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-lg-4">
						<div className="form-group">
							<label htmlFor="website">Gender</label>
							<select
								id="gender"
								name="gender"
								className="form-control"
								value={values.gender}
								onChange={handleChange}
							>
								<option value="No Answer">Prefer Not To Answer</option>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
								<option value="Not Listed">Not Listed</option>
							</select>
						</div>
					</div>
					<div className="col-12 col-sm-6 col-lg-4">
						<div className="form-group birth_date">
							<label htmlFor="birth_date">Birth Date</label>
							<DatePicker
								id="birth_date"
								name="birth_date"
								className="form-control"
								autoComplete="off"
								selected={Date.parse(birthDate)}
								onChange={(date) => setBirthDate(date)}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
							/>
						</div>
					</div>
				</div>
				<div className="form-row">
					<div className="col">
						<img
							className="img-fluid d-block"
							id="upload-image"
							src={user && user.image ? config.baseIMGURL + user.image : DefaultAvatar}
							alt="Profile Avatar"
							style={{ height: '64px', margin: 'auto', width: 'auto' }}
						/>
					</div>
					<div className="col-12 col-sm-10 col-md-11">
						<div className="form-group">
							<label htmlFor="biography">Image</label>
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									id="image"
									name="image"
									onChange={handleImageChange}
									accept="image/*"
								/>
								<label className="custom-file-label" htmlFor="customFile">
									{image ? image.name : 'Choose File'}
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end mt-3">
					{loading ? (
						<button className="btn btn-sm btn-gray" type="submit" disabled>
							<Loading />
						</button>
					) : (
						<button className="btn btn-sm btn-gray" type="submit">
							Save Profile Changes
						</button>
					)}
				</div>
			</form>
		</div>
	);
};

export default SettingsProfile;
