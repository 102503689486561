import React, { useState } from 'react';

const Filter = ({ initial, name, options, handleChange }) => {
	const [ filter, setFilter ] = useState(initial);

	const handleSelect = (e) => {
		setFilter(e.target.value);
		handleChange(e);
	};

	return (
		<select className="custom-select" id={name} name={name} value={filter} onChange={handleSelect}>
			{options.map((item, index) => (
				<option key={index} value={item.value}>
					{item.title}
				</option>
			))}
		</select>
	);
};

export default Filter;
