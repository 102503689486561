import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchHelp } from '../redux/actions';

import HelpHead from '../containers/HelpHead';
import HelpFAQs from '../containers/HelpFAQs';
import HelpLast from '../containers/HelpLast';

import MetaTag from '../components/MetaTags';
import Loading from '../components/loading/BouncingDots';
import Message from '../components/messages/DisplayMessage';

const Help = () => {
	const dispatch = useDispatch();
	const helpData = useSelector((state) => state.help);

	useEffect(() => {
		document.title = 'Help - WithinPH';

		// fetch FAQS if redux is empty
		if (Object.keys(helpData.faqs).length === 0 && !helpData.error && !helpData.loading) {
			dispatch(fetchHelp());
		}
	}, []);

	return (
		<div className="HelpPage">
			<MetaTag data={{ title: 'Help', slug: 'help' }} type="page" />
			{helpData.loading ? (
				<Loading text="loading page" />
			) : helpData.error ? (
				<Message message={helpData.error} />
			) : (
				<React.Fragment>
					<HelpHead />
					<div className="row">
						<div className="col-10 mx-auto">
							{helpData.faqs.length > 0 &&
								helpData.faqs.map((faq, index) => (
									<HelpFAQs key={`HelpFAQ-${index}`} index={index} faq={faq} />
								))}
							<HelpLast />
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default Help;
