import axios from 'axios';
import config from '../../utils/config';

export const fetchEventsRequest = () => {
	return {
		type: 'FETCH_EVENTS_REQUEST'
	};
};

export const fetchEventsNxtRqst = () => {
	return {
		type: 'FETCH_EVENTS_NXTRQST'
	};
};

export const fetchEventsSuccess = (data) => {
	return {
		type: 'FETCH_EVENTS_SUCCESS',
		payload: data
	};
};

export const fetchEventsNxtPage = (data) => {
	return {
		type: 'FETCH_EVENTS_NXTPAGE',
		payload: data
	};
};

export const fetchEventsFailure = (data) => {
	return {
		type: 'FETCH_EVENTS_FAILURE',
		payload: data
	};
};

/* 
** Fetch New Set of Events
*/
export const fetchEvents = (url = null, parameters = '', auth = false) => {
	url = url ? url : config.baseAPIURL + 'events';

	let params = {};
	if(auth) {
		params.user_id = JSON.parse(localStorage.withinPHToken).id;
	}

	let hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchEventsRequest());
		axios
			.get(url + parameters, { params: params }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				data.filter = parameters;
				dispatch(fetchEventsSuccess(data));
			})
			.catch((error) => {
				const emsg = error.message;
				dispatch(fetchEventsFailure(emsg));
			});
	};
};
 
/*
** Fetch Next Page of Events
*/
export const fetchMoreEvents = (url, parameters = '', auth = false) => {
	url = url ? url : config.baseAPIURL + 'events';
	let hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	let params = {};
	if(auth) {
		params.user_id = JSON.parse(localStorage.withinPHToken).id;
	}

	return (dispatch) => {
		dispatch(fetchEventsNxtRqst());
		axios
			.get(url, { params: params }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				data.filter = parameters;
				dispatch(fetchEventsNxtPage(data));
			})
			.catch((error) => {
				const emsg = error.message;
				dispatch(fetchEventsFailure(emsg));
			});
	};
};
