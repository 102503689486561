/*
** Convert City Details
** to City Footnotes
**  
** Parameter: Object City Details
** Return: String Footnotes
*/
export function createCityFootnotes(details) {
	return `Details about ${details.name} displayed on this page are information we've gathered from various websites while ratings shown above are aggregated opinions from WithinPH's users. These information are dynamic by nature and ratings can be very subjective hence we highly suggest that you double-check data found on this site before travelling to ${details.name}, ${details.province_name}, Philippines.`;
}
