import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ErrorMsg extends Component {
	render() {
		return (
			<section className="middle-card-sector ErrorMsg">
				{this.props.message}
				{this.props.buttons && (
					<p className="mt-3">
						<Link to="/" className="btn btn-gray btn-sm mr-3">
							<i className="fas fa-home mr-1" /> Take Me Home
						</Link>
						<Link to="/help" className="btn btn-green btn-sm">
							<i className="fas fa-life-ring mr-1" /> Get Help
						</Link>
					</p>
				)}
			</section>
		);
	}
}

export default ErrorMsg;
