import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from '../../utils/hooks';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

import VerificationLink from './VerificationLink';
import SettingsDelete from './SettingsDelete';

const SettingsAccounts = ({ user, handleUpdate }) => {
	const isLoading = useSelector((state) => state.auth.accountLoading);
	const hasErrors = useSelector((state) => state.auth.accountError);

	const handleSend = () => {
		setLoading(true);
		handleUpdate('accounts-emails', values);
	};

	const { values, errMsg, handleChange, handleSubmit } = useForm({ email: user.email }, handleSend, true);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(false);
				setError(hasErrors);
				window.scrollBy({
					top: -2500,
					left: 0,
					behavior: 'smooth'
				});
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
				window.scrollBy({
					top: -2500,
					left: 0,
					behavior: 'smooth'
				});
			}

			setLoading(isLoading);
		},
		[ errMsg, hasErrors, isLoading ]
	);

	return (
		<div className="card-body">
			{error && <Message type="alert-danger" message={error} />}
			On this page, you can update your e-mail address anytime using the form below. E-mail address is not
			displayed publicly and will only be used if you wish to receive certain notifications or news from WithinPH.
			You can also permanently delete your WithinPH account using the 'DELETE' button below.
			<form id="profiles" className="settings-form form-inline d-flex" onSubmit={handleSubmit}>
				<label className="" htmlFor="email">
					Email Address
				</label>

				<div className="input-group pt-0 pb-2 pb-sm-0 px-sm-2 flex-fill">
					<input
						id="email"
						name="email"
						type="text"
						className="form-control form-control-sm"
						value={values.email}
						onChange={handleChange}
					/>
					<div className="input-group-append">
						<span
							className={
								user.is_verified ? (
									'input-group-text text-white bg-success'
								) : (
									'input-group-text text-white bg-danger'
								)
							}
						>
							{user.is_verified ? <i className="fas fa-check" /> : <i className="fas fa-times" />}
						</span>
					</div>
				</div>

				{loading ? (
					<button className="btn btn-sm btn-gray" type="submit" disabled>
						<Loading />
					</button>
				) : (
					<button className="btn btn-sm btn-gray" type="submit">
						Update Email Address
					</button>
				)}
			</form>
			<small className="form-text text-muted">
				{user.is_verified ? (
					'Your current account email address is verified already. Please note that you need to re-verify should you change your email address.'
				) : (
					<React.Fragment>
						<span className="mr-1">
							Your current account email address is not yet verified. Please check your email for
							instruction or click the link to receive a new verification link. If you think you are
							already verified, please refresh the page or contact us.
						</span>
						<VerificationLink />
					</React.Fragment>
				)}
			</small>
			<SettingsDelete handleUpdate={handleUpdate} />
		</div>
	);
};

export default SettingsAccounts;
