// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// React, Router, & Redux
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { hydrate, render } from 'react-dom';

// Package Stylesheets
import './assets/css/fontawesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

// StyleSheet
import './assets/css/style.css';

import App from './App';
import store from './redux/store';

import * as serviceWorker from './serviceWorker';


const APP = (
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
	hydrate(APP, rootElement);
} else {
	render(APP, rootElement);
}

serviceWorker.unregister();
