const initState = {
	forgotLoading: false,
	forgotError: '',
	forgotMessage: '',
	resetsLoading: false,
	resetsError: '',
	resetsMessage: ''
};

const passwordReducer = (state = initState, action) => {
	switch (action.type) {
		case 'ACTION_FORGOT_REQUEST':
			return {
				...state,
				forgotLoading: true,
				forgotError:   '',
				forgotMessage: ''
			};

		case 'ACTION_FORGOT_SUCCESS':
			return {
				...state,
				forgotLoading: false,
				forgotError:   '',
				forgotMessage: action.payload
			};

		case 'ACTION_FORGOT_FAILURE':
			return {
				...state,
				forgotLoading: false,
				forgotError:   action.payload,
				forgotMessage: ''
			};

		case 'ACTION_RESETS_REQUEST':
			return {
				...state,
				resetsLoading: true,
				resetsError:   '',
				resetsMessage: ''
			};

		case 'ACTION_RESETS_SUCCESS':
			return {
				...state,
				resetsLoading: false,
				resetsError:   '',
				resetsMessage: action.payload,
			};

		case 'ACTION_RESETS_FAILURE':
			return {
				...state,
				resetsLoading: false,
				resetsError:   action.payload,
				resetsMessage: ''
			};
		case 'RESET_ACTIONS':
			return {
				forgotLoading: false,
				forgotError:   '',
				forgotMessage: '',
				resetsLoading: false,
				resetsError:   '',
				resetsMessage: ''
			};
		default:
			return state;
	}
};

export default passwordReducer;
