import { combineReducers } from 'redux';

import authReducer from './authReducer';
import cityDetailReducer from './cityDetailReducer';
import cityReducer from './cityReducer';
import eventReducer from './eventReducer';
import helpReducer from './helpReducer';
import pageReducer from './pageReducer';
import passwordReducer from './passwordReducer';
import profileReducer from './profileReducer';
import provinceReducer from './provinceReducer';
import tripReducer from './tripReducer';
import reviewReducer from './reviewReducer';

const rootReducer = combineReducers({
	auth: authReducer,
	city: cityReducer,
	cityDetail: cityDetailReducer,
	event: eventReducer,
	help: helpReducer,
	page: pageReducer,
	password: passwordReducer,
	profile: profileReducer,
	province: provinceReducer,
	trip: tripReducer,
	review: reviewReducer
});

export default rootReducer;
