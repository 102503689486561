import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { userVerify } from './redux/actions/';

import SignInModal from './components/modals/AuthSignIn';
import SignUpModal from './components/modals/AuthSignUp';
import LoadingBox from './components/loading/LoadingEllipsis';
import SiteHeader from './containers/SiteHeader';
import SiteFooter from './containers/SiteFooter';

import City from './pages/City';
import List from './pages/List';
import Help from './pages/Help';
import NotFound from './pages/NotFound';
import Auth from './pages/Auth';
import Event from './pages/Event';
import Page from './pages/Page';
import Profile from './pages/Profile';
import Reviews from './pages/Reviews';
import Settings from './pages/Settings';
import Verify from './pages/Verify';

// configure toast notification
toast.configure({ position: toast.POSITION.TOP_RIGHT, autoClose: false });

const App = () => {
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const isLoading = useSelector((state) => state.auth.isLoading);

	const [ loading, setLoading ] = useState(isLoading);
	const [ hideModal, setHideModal ] = useState(isAuthenticated);

	useEffect(
		() => {
			setHideModal(isAuthenticated);

			/*
			** check if token is available 
			** then fetch user data
			*/
			let usertoken = localStorage.withinPHToken;
			if (!isAuthenticated && !!usertoken) {
				dispatch(userVerify());
			} else {
				setLoading(false);
			}

			/* 
			** Initialize 
			** Google Analytics
			*/
			ReactGA.initialize('UA-60504117-12');
			ReactGA.pageview(window.location.pathname + window.location.search);
		},
		[ isAuthenticated, dispatch ]
	);

	return loading ? (
		<div className="apploading animate grow">
			<LoadingBox />
			<span className="apploading-text">loading</span>
		</div>
	) : (
		<div className="App animate">
			<SiteHeader />
			
			<main className="middle container-fluid">
				<Switch>
					<Route exact path="/" component={List} />

					{/* Pages */}
					<Route exact path="/about/"  component={Page} />
					<Route exact path="/policy/" component={Page} />
					<Route exact path="/terms/"  component={Page} />
					<Route exact path="/help/"   component={Help} />
					<Route exact path="/404/"    component={NotFound} />

					<Route exact path="/events/" component={Event} />
					<Route exact path="/events/:province/" component={Event} />
					
					{/* Authentication */}
					<Route exact path="/auth/:type/:token?" component={Auth} />
					
					{/* Email Verification */}
					<Route exact path="/verify/" component={Verify} />
					<Route exact path="/verify/:token/" component={Verify} />

					{/* Users */}
					<Route exact path="/u/" component={Profile} />
					<Route exact path="/u/:username" component={Profile} />
					<Route exact path="/settings" component={Settings} />

					<Route exact path="/review/" component={Reviews} />
					<Route exact path="/review/:province/:city/" component={Reviews} />

					{/* Province & City */}
					<Route exact path="/:province/" component={List} />
					<Route exact path="/:province/:city/" component={City} />
				</Switch>
			</main>
			<SiteFooter />
			
			{/* <div className="alert alert-green alert-dismissible fade show fixed-bottom sticky-alert" role="alert">
				<i className="fas fa-exclamation-triangle mr-1" /> COVID-19 TRAVEL ALERT: For more information on Philippine
				Travel Advisory, you may click{' '}
				<strong>
					<a href="https://philippines.travel/safeph" target="_blank" rel="nofollow noopener noreferrer">
						here
					</a>
				</strong>
				<button type="button" className="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div> */}

			{/* Modal Forms - Authentication */}
			{!hideModal && <SignInModal />}
			{!hideModal && <SignUpModal />}
		</div>
	);
};

export default App;
