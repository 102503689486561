import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CardLink extends Component {
	render() {
		return (
			<div className="CityLink col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch">
				<div className="card card-wrapper card-adv">
					<img className="card-img-top" src="/img/events.png" alt="List of Philippine Festivals" />
					<div className="card-body">
						<p className="card-text">
							Check out the list of the best and most vibrant festivals in the Philippines...
						</p>
						<Link to="/events/" className="btn btn-sm">
							See all the Festivals in PH&nbsp;&nbsp;<i className="fas fa-arrow-right" />
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default CardLink;
