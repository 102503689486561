import axios from 'axios';
import config from '../../utils/config';

export const fetchPageRequest = () => {
	return {
		type: 'FETCH_PAGE_REQUEST'
	};
};

export const fetchPageSuccess = (data) => {
	return {
		type: 'FETCH_PAGE_SUCCESS',
		payload: data
	};
};

export const fetchPageFailure = (data) => {
	return {
		type: 'FETCH_PAGE_FAILURE',
		payload: data
	};
};

/*
** Fetch Page Content
*/
export const fetchPage = (page) => {
	const url = config.baseAPIURL + 'pages/' + page;
	const hdr = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
        dispatch(fetchPageRequest());
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
                response.data.type = page;
				dispatch(fetchPageSuccess(response.data));
			})
			.catch((error) => {
                const emsg = error.message;
                dispatch(fetchPageFailure(emsg));
            });
	};
};
