import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

import { fetchTrips, deleteTrip } from '../redux/actions';

import Message from '../components/messages/DisplayMessage';
import Pagination from '../components/Pagination';
import Loading from '../components/loading/BouncingDots';
import ConfirmDelete from '../components/modals/ConfirmDelete';
import Trip from './ProfileTripItem';

const ProfileTripList = ({ user, username }) => {
	const dispatch = useDispatch();

	const hasErrors = useSelector((state) => state.trip.fetchError);
	const isLoading = useSelector((state) => state.trip.fetchLoading);
	const trips = useSelector((state) => state.trip.trips);

	const [ errMess, setErrMess ] = useState('');

	const [ currentPage, setCurrentPage ] = useState(1);
	const [ tripsPerPage ] = useState(10);

	const [ tripToDelete, setTripToDelete ] = useState({ id: null, title: null });

	const idxOfLastItem = currentPage * tripsPerPage;
	const idxOfFrstItem = idxOfLastItem - tripsPerPage;
	const curSetofItems = trips && trips.length > 0 ? trips.slice(idxOfFrstItem, idxOfLastItem) : trips;

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	useEffect(
		() => {
			if (username) {
				dispatch(fetchTrips(username));
			}
		},
		[ username ]
	);

	useEffect(
		() => {
			if (hasErrors) {
				setErrMess(hasErrors);
			}
		},
		[ hasErrors, isLoading ]
	);

	const handleClick = (data) => {
		setTripToDelete(data);
		$('#DeleteModal').modal('show');
	};

	const handleDelete = () => {
		const params = { trip_id: tripToDelete.id, title: tripToDelete.title, username: username };
		dispatch(deleteTrip(params));
		setCurrentPage(1);
		$('#DeleteModal').modal('hide');
	};

	return (
		<div className="content-table TripList">
			{isLoading ? (
				<Loading text="collecting all trips" />
			) : errMess ? (
				<Message message={errMess} />
			) : trips && trips.length ? (
				<React.Fragment>
					{curSetofItems.map((trip) => (
						<div key={`TRIP-${trip.id}`} className="tripsTable">
							<Trip
								trip={trip}
								handleDelete={handleClick}
								displayButtons={user && user.username === username}
							/>
						</div>
					))}

					{trips.length > tripsPerPage && (
						<Pagination itemsPerPage={tripsPerPage} totalItems={trips.length} paginate={paginate} />
					)}
				</React.Fragment>
			) : (
				<Message message="No Trips Yet" />
			)}
			{user && user.username === username ? (
				<ConfirmDelete id="DeleteModal" type="trip" text={tripToDelete.title} handleDelete={handleDelete} />
			) : (
				''
			)}
		</div>
	);
};

export default ProfileTripList;
