import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
	const pageNumbers = [],
		lastPage = Math.ceil(totalItems / itemsPerPage);
	const [ currentPage, setCurrentPage ] = useState(1);

	useEffect(
		() => {
			paginate(currentPage);
		},
		[ currentPage ]
	);

	const handleClick = (e) => {
		e.preventDefault();
		setCurrentPage(e.target.name);
	};

	const handlePrevClick = (e) => {
		e.preventDefault();

		const page = parseInt(currentPage) - 1;
		setCurrentPage(page);
	};

	const handleNextClick = (e) => {
		e.preventDefault();

		const page = parseInt(currentPage) + 1;
		setCurrentPage(page);
	};

	for (let i = 1; i <= lastPage; i++) {
		pageNumbers.push(i);
	}

	return (
		<nav className="d-flex justify-content-between pagination-grp">
			<div className="prev-page d-none d-md-inline">
				<button onClick={handlePrevClick} disabled={parseInt(currentPage) === 1 ? true : false}>
					Prev
				</button>
			</div>
			<ul className="pagination flex-wrap">
				{pageNumbers.map((number) => (
					<li key={number} className={parseInt(currentPage) === number ? 'page-item active' : 'page-item'}>
						<NavLink to="#" name={number} onClick={handleClick} className="page-link ">
							{number}
						</NavLink>
					</li>
				))}
			</ul>
			<div className="next-page d-none d-md-inline">
				<button onClick={handleNextClick} disabled={parseInt(currentPage) === lastPage ? true : false}>
					Next
				</button>
			</div>
		</nav>
	);
};

export default Pagination;
