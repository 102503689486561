const initState = {
	loading: false,
	error: '',
	profiles: {}
};

const profileReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_PROFILE_REQUEST':
			return {
				...state,
				loading: true,
				error: ''
			};

		case 'FETCH_PROFILE_SUCCESS':
			const profile = [];
			profile[action.payload.username] = action.payload;

			return {
				profiles: { ...state.profiles, ...profile },
				error: '',
				loading: false
			};

		case 'FETCH_PROFILE_FAILURE':
			return {
				...state,
				error: action.payload,
				loading: false
			};

		default:
			return state;
	}
};

export default profileReducer;
