const initState = {};

const provinceReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_PROVINCES_SUCCESS':
			return {
				...action.payload
			};

		default:
			return state;
	}
};

export default provinceReducer;
