import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { useForm } from '../../utils/hooks';
import { userSignIn } from '../../redux/actions';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

const AuthSignIn = ({ ...props }) => {
	const dispatch = useDispatch();

	const hasErrors = useSelector((state) => state.auth.error);
	const isLoading = useSelector((state) => state.auth.isLoading);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const signin = () => {
		setLoading(true);
		dispatch(userSignIn(values));
	};

	const { values, errMsg, handleChange, handleSubmit } = useForm({ username: '', password: '' }, signin, true);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(isLoading);
				setError(hasErrors);
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
			}
		},
		[ errMsg, hasErrors, isLoading ]
	);

	return (
		<div>
			{isAuthenticated && <Redirect to="/" />}

			{error && (
				<Message type="alert-danger" message={error} />
			)}

			<form className="auth-form" onSubmit={handleSubmit}>
				<div className="form-group">
					<label htmlFor="username">
						Email or Username
					</label>
					<input
						type="text"
						id="username"
						name="username"
						className="form-control"
						onChange={handleChange}
						value={values.username}
						required
					/>
				</div>

				<div className="form-group">
					<label htmlFor="password">
						Password
					</label>
					<input
						type="password"
						id="password"
						name="password"
						className="form-control"
						onChange={handleChange}
						value={values.password}
						required
					/>
				</div>
				<div className="footnote">
					<Link to="/auth/forgot">Forgot Password?</Link>
					<br />
					<br />
				</div>
				{loading ? (
					<button className="btn btn-gray btn-block" type="submit" disabled>
						<Loading />
					</button>
				) : (
					<button className="btn btn-gray btn-block" type="submit">
						Login
					</button>
				)}
				<div className="footnote">
					Not yet a member?&nbsp;
					{props.isPage ? (
						<Link to="/auth/signup">Join Us</Link>
					) : (
						<Link to="/auth/signup" data-toggle="modal" data-target="#signUpModal" data-dismiss="modal">
							Join Us
						</Link>
					)}
				</div>
			</form>
		</div>
	);
};

export default AuthSignIn;
