import React, { Component } from 'react';

class AlertMsg extends Component {
	handleClick() {
		// clear alert message redux
	}

	render() {
		return (
			<div className={`alert ${this.props.type} alert-dismissible fade show`} role="alert">
				{this.props.message}
				<button type="button" className="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);
	}
}

export default AlertMsg;
