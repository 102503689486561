import React, { Component } from 'react';

class StarRatingInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rating: 0
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick = (e) => {
		this.setState({
			rating: parseInt(e.target.value)
		});

		this.props.handleChange(e);
	};

	render() {
		return (
			<div
				className={`reviewform-stars ${this.props.small
					? 'reviewform-stars-sm'
					: ''} d-flex justify-content-end flex-row-reverse`}
			>
				{Array(5).fill().map((x, i) => (
					<React.Fragment key={`${this.props.text}-${Math.abs(i - 5)}`}>
						<input
							type="radio"
							id={`${this.props.text}-${Math.abs(i - 5)}`}
							name={this.props.text}
							value={Math.abs(i - 5)}
							checked={this.state.rating === Math.abs(i - 5)}
							onChange={this.handleClick}
						/>
						<label htmlFor={`${this.props.text}-${Math.abs(i - 5)}`} />
					</React.Fragment>
				))}
			</div>
		);
	}
}

export default StarRatingInput;
