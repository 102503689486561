import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { updateUser } from '../redux/actions';

import Accounts from '../components/forms/SettingsAccounts';
import Password from '../components/forms/SettingsPassword';
import Profiles from '../components/forms/SettingsProfiles';

import MetaTag from '../components/MetaTags';

const Settings = ({ props }) => {
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const user = useSelector((state) => state.auth.user);

	const [ activeTab, setActiveTab ] = useState('one');

	const handleUpdate = (id, data) => {
		switch (id) {
			case 'profiles':
				dispatch(updateUser(data, 'profile'));
				break;
			case 'password':
				dispatch(updateUser(data, 'password'));
				break;
			case 'accounts-emails':
				dispatch(updateUser(data, 'account'));
				break;
			case 'accounts-delete':
				dispatch(updateUser(null, 'delete'));
				break;
			default:
			// code block
		}
	};

	const handleClicks = (e) => {
		e.preventDefault();
		setActiveTab(e.target.id);
	};

	return (
		<section className="middle-page-main SettingsPage">
			{!isAuthenticated && <Redirect to="/auth/signin" />}
			<MetaTag follow={false} />
			<div className="container">
				<ul id="tabs" className="nav nav-tabs  nav-fill" role="tablist">
					<li className="nav-item">
						<Link
							id="one"
							to="#pane-A"
							className="nav-link active"
							data-toggle="tab"
							role="tab"
							onClick={handleClicks}
						>
							Update Profile
						</Link>
					</li>
					<li className="nav-item">
						<Link
							id="two"
							to="#pane-B"
							className="nav-link"
							data-toggle="tab"
							role="tab"
							onClick={handleClicks}
						>
							Change Password
						</Link>
					</li>
					<li className="nav-item">
						<Link
							id="tri"
							to="#pane-C"
							className="nav-link"
							data-toggle="tab"
							role="tab"
							onClick={handleClicks}
						>
							Account Settings
						</Link>
					</li>
				</ul>

				<div id="content" className="tab-content" role="tablist">
					<div
						id="one"
						className={activeTab === 'one' ? 'card tab-pane show active' : 'card tab-pane fade'}
						role="tabpanel"
						aria-labelledby="one"
					>
						<div className="card-header" role="tab" id="heading-A">
							<h6>
								<i className="fas fa-id-card mr-1" />
								<Link
									id="one"
									onClick={handleClicks}
									className="collapsed"
									data-toggle="collapse"
									to="#collapse-one"
									aria-expanded="false"
									aria-controls="collapse-one"
								>
									Update Profile
								</Link>
							</h6>
						</div>

						<div
							id="collapse-one"
							className={activeTab === 'one' ? 'collapse show' : 'collapse'}
							data-parent="#content"
							role="tabpanel"
							aria-labelledby="heading-one"
						>
							<Profiles user={user} handleUpdate={handleUpdate} />
						</div>
					</div>

					<div
						id="two"
						className={activeTab === 'two' ? 'card tab-pane show active' : 'card tab-pane fade'}
						role="tabpanel"
						aria-labelledby="two"
					>
						<div className="card-header" role="tab" id="heading-B">
							<h6>
								<i className="fas fa-key mr-1" />
								<Link
									id="two"
									onClick={handleClicks}
									className="collapsed"
									data-toggle="collapse"
									to="#collapse-two"
									aria-expanded="false"
									aria-controls="collapse-two"
								>
									Change Password
								</Link>
							</h6>
						</div>
						<div
							id="collapse-two"
							className={activeTab === 'two' ? 'collapse show' : 'collapse'}
							data-parent="#content"
							role="tabpanel"
							aria-labelledby="heading-two"
						>
							<Password handleUpdate={handleUpdate} />
						</div>
					</div>

					<div
						id="tri"
						className={activeTab === 'tri' ? 'card tab-pane show active' : 'card tab-pane fade'}
						role="tabpanel"
						aria-labelledby="tri"
					>
						<div className="card-header" role="tab" id="heading-tri">
							<h6>
								<i className="fas fa-cog mr-1" />
								<Link
									id="tri"
									onClick={handleClicks}
									className="collapsed"
									data-toggle="collapse"
									to="#collapse-tri"
									aria-expanded="false"
									aria-controls="collapse-tri"
								>
									Account Settings
								</Link>
							</h6>
						</div>
						<div
							id="collapse-tri"
							className={activeTab === 'tri' ? 'collapse show' : 'collapse'}
							role="tabpanel"
							data-parent="#content"
							aria-labelledby="heading-tri"
						>
							<Accounts user={user} handleUpdate={handleUpdate} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Settings;
