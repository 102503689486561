import axios from 'axios';
import config from '../../utils/config';

export const fetchCityDetailsRequest = () => {
	return {
		type: 'FETCH_CITYDETAILS_REQUEST'
	};
};

export const fetchCityDetailsSuccess = (data) => {
	return {
		type: 'FETCH_CITYDETAILS_SUCCESS',
		payload: data
	};
};

export const fetchCityDetailsFailure = (data) => {
	return {
		type: 'FETCH_CITYDETAILS_FAILURE',
		payload: data
	};
};

// param = query string 'province_name/city_name'
export const fetchCityDetails = (param) => {
	let url = config.baseAPIURL + `cities/${param}`;
	let hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchCityDetailsRequest());
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				dispatch(fetchCityDetailsSuccess(response.data));
			})
			.catch((error) => {
				const emsg = error.message;
				dispatch(fetchCityDetailsFailure(emsg));
			});
	};
};
