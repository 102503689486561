import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Rating from '../components//StarRatingDisplay';

const ProfileTrip = ({ trip, displayButtons = false, handleDelete }) => {
	const handleClick = (e) => {
		handleDelete({
			id: e.target.getAttribute('data-tripid'),
			title: e.target.getAttribute('data-triptitle')
		});
	};

	return (
		<div className={`tripsTableBody ${trip.rating !== null ? "tripsTableBodyReviewed" : ""}`}>
			<div className="row">
				<div className="col" style={{ maxWidth: '100px' }}>
					{trip.s_date !== null ? (
						moment(trip.s_date).calendar(null, {
							lastDay: '[Yesterday]',
							sameDay: '[Today]',
							nextDay: '[Tomorrow]',
							lastWeek: '[Last] dddd',
							nextWeek: 'dddd',
							sameElse: 'YYYY MMM. DD'
						})
					) : (
						'No Date'
					)}
				</div>

				<div className="col" style={{ maxWidth: '50px' }}>
					{trip.e_date !== null && trip.s_date !== null ? (
						moment
							.duration(moment(trip.e_date).startOf('day').diff(moment(trip.s_date).startOf('day')))
							.asDays() +
						1 +
						'd'
					) : (
						'0d'
					)}
				</div>
				<div className="col">
					<Link
						to={`/${trip.province_slug}/${trip.city_slug}`}
						title={`${trip.city_name}, ${trip.province_name}`}
					>
						{trip.city_name}
					</Link>
					<span className="d-none d-sm-inline">
						, <Link to={`/${trip.province_slug}`}>{trip.province_name}</Link>
					</span>
					{trip.rating && (
						<div className="trip-rating d-none d-sm-block">
							Rated {trip.city_name} - <Rating rating={trip.rating} />{' '}
							{trip.review && <span> - {trip.review}</span>}
						</div>
					)}
				</div>
				{displayButtons && (
					<div className="col btn-group" style={{ maxWidth: '120px' }}>
						<button
							id={trip.id}
							className="btn btn-md btn-borderless px-2"
							onClick={handleClick}
							data-tripid={trip.id}
							data-triptitle={`${trip.city_name}, ${trip.province_name}`}
							title="Remove"
						>
							<i
								className="fas fa-trash"
								data-tripid={trip.id}
								data-triptitle={`${trip.city_name}, ${trip.province_name}`}
								onClick={handleClick}
							/>
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfileTrip;
