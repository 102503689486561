import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TripForm from '../forms/TripForm';

const TripModal = () => {
	const usernames = useSelector((state) => state.auth.user.username);
	const hasErrors = useSelector((state) => state.trip.createError);
	const isLoading = useSelector((state) => state.trip.actionLoading);

	const [ added, setAdded ] = useState(false);
	const [ watch, setWatch ] = useState(false);

	const handleAddTrip = (data) => {
		setWatch(true);
	};

	const handleRefresh = () => {
		setAdded(false);
	};

	const handleClose = () => {
		handleRefresh();
	};

	useEffect(
		() => {
			if (watch) {
				if (!isLoading && !hasErrors) {
					setAdded(true);
					setWatch(false);
				}
			}
		},
		[ watch, isLoading, hasErrors ]
	);

	return (
		<div id="TripModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h6 className="modal-title">Add Your Recent Trip</h6>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={handleClose}
						>
							<span aria-hidden="true" onClick={handleClose}>
								×
							</span>
						</button>
					</div>
					<div className="modal-body">
						{added ? (
							<div>
								<h6>Your recent trip has been added successfully!</h6>
								<button type="button" className="btn btn-green btn-sm" onClick={handleRefresh}>
									Add Another Trip
								</button>
							</div>
						) : (
							<TripForm handleAddTrip={handleAddTrip} username={usernames} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TripModal;
