import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../utils/config';

export const fetchTripsRequest = () => {
	return {
		type: 'FETCH_TRIPS_REQUEST'
	};
};

export const fetchTripsFailure = (data) => {
	return {
		type: 'FETCH_TRIPS_FAILURE',
		payload: data
	};
};

export const fetchTripsSuccess = (data) => {
	return {
		type: 'FETCH_TRIPS_SUCCESS',
		payload: data
	};
};

export const actionTripRequest = () => {
	return {
		type: 'ACTION_TRIP_REQUEST'
	};
};

export const createTripSuccess = () => {
	return {
		type: 'CREATE_TRIP_SUCCESS'
	};
};

export const deleteTripSuccess = () => {
	return {
		type: 'DELETE_TRIP_SUCCESS'
	};
};

export const actionTripFailure = (data) => {
	return {
		type: 'ACTION_TRIP_FAILURE',
		payload: data
	};
};

/* 
** Fetch Trips
** of specific profile
*/
export const fetchTrips = (param) => {
	const url = config.baseAPIURL + 'trips/get/' + param;
	const hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};
	return (dispatch) => {
		dispatch(fetchTripsRequest());
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				dispatch(fetchTripsSuccess(data));
			})
			.catch((error) => {
				const emsg = error.message || 'failed to fetch list of trips of @' + param;
				dispatch(fetchTripsFailure(emsg));
			});
	};
};

/* 
** Create Trip
*/
export const createTrip = (param) => {
	const userID = JSON.parse(localStorage.withinPHToken).id;
	const tokens = JSON.parse(localStorage.withinPHToken).data;

	const url = config.baseAPIURL + 'trips/create';
	const hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`,
		'Content-Type': 'application/json'
	};

	const prm = { ...param, user_id: userID };

	return (dispatch) => {
		if (!userID || !tokens) {
			dispatch(actionTripFailure('Authentication Failed!'));
		} else {
			dispatch(actionTripRequest());
		}

		axios
			.post(url, prm, { headers: hdr })
			.then((response) => {
				dispatch(createTripSuccess(response.data));
				dispatch(fetchTrips(prm.username));
			})
			.catch((error) => {
				let err = [];
				err['message'] = error.message || 'Failed to create new trip. Please try again.';
				err['type'] = 'create';
				dispatch(actionTripFailure(err));
			});
	};
};

/* 
** Delete Trip
*/
export const deleteTrip = (param) => {
	const userID = JSON.parse(localStorage.withinPHToken).id;
	const tokens = JSON.parse(localStorage.withinPHToken).data;

	const url = config.baseAPIURL + 'trips/delete';
	const hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`,
		'Content-Type': 'application/json'
	};
	const prm = { ...param, user_id: userID };

	return (dispatch) => {
		if (!userID || !tokens) {
			dispatch(actionTripFailure('Authentication Failed!'));
		} else {
			dispatch(actionTripRequest());
		}

		axios
			.post(url, prm, { headers: hdr })
			.then((response) => {
				toast.success(`Your trip to ${prm.title} has been deleted successfully!`);
				dispatch(deleteTripSuccess(response.data));
				dispatch(fetchTrips(prm.username));
			})
			.catch((error) => {
				let err = [];
				err['message'] = error.message || `Failed to delete your trip to ${prm.title}. Please try again.`;
				err['type'] = 'create';
				toast.error('Failed to delete your trip. Please try again.');
				dispatch(actionTripFailure(err));
			});
	};
};