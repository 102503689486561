const initState = {
	user: [],
	isAuthenticated: false,
	isLoading: true,
	error: '',
	profileLoading: false,
	profileError: '',
	accountLoading: false,
	accountError: '',
	deleteLoading: false,
	deleteError: '',
	passwordLoading: false,
	passwordError: ''
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_AUTH_REQUEST':
			return {
				...state,
				error: '',
				isAuthenticated: false,
				isLoading: true
			};

		case 'FETCH_AUTH_SUCCESS':
			return {
				...state,
				user: { ...action.payload },
				isAuthenticated: true,
				isLoading: false,
				error: ''
			};

		case 'FETCH_AUTH_FAILURE':
			return {
				...state,
				user: [],
				isAuthenticated: false,
				isLoading: false,
				error: action.payload
			};

		case 'FETCH_AUTH_SIGNOUT':
			return {
				...state,
				user: [],
				isAuthenticated: false,
				isLoading: false,
				error: ''
			};

		case 'UPDATE_USER_REQUEST':
			if (action.code === 'profile') {
				return {
					...state,
					profileError: '',
					profileLoading: true
				};
			}
			if (action.code === 'account') {
				return {
					...state,
					accountError: '',
					accountLoading: true
				};
			}
			if (action.code === 'password') {
				return {
					...state,
					passwordError: '',
					passwordLoading: true
				};
			}
			if (action.code === 'delete') {
				return {
					...state,
					deleteError: '',
					deleteLoading: true
				};
			}
			break;

		case 'UPDATE_USER_SUCCESS':
			if (action.code === 'profile') {
				return {
					...state,
					user: { ...action.payload },
					profileError: '',
					profileLoading: false
				};
			}
			if (action.code === 'account') {
				return {
					...state,
					user: { ...action.payload },
					accountError: '',
					accountLoading: false
				};
			}
			if (action.code === 'password') {
				return {
					...state,
					user: [],
					isAuthenticated: false,
					passwordError: '',
					passwordLoading: false
				};
			}
			if (action.code === 'delete') {
				return {
					...state,
					user: [],
					isAuthenticated: false,
					deleteError: '',
					deleteLoading: false
				};
			}
			break;

		case 'UPDATE_USER_FAILURE':
			if (action.code === 'profile') {
				return {
					...state,
					profileError: action.payload,
					profileLoading: false
				};
			}
			if (action.code === 'account') {
				return {
					...state,
					accountError: action.payload,
					accountLoading: false
				};
			}
			if (action.code === 'password') {
				return {
					...state,
					passwordError: action.payload,
					passwordLoading: false
				};
			}
			if (action.code === 'delete') {
				return {
					...state,
					deleteError: action.payload,
					deleteLoading: false
				};
			}
			break;

		default:
			return state;
	}
};

export default authReducer;
