import React from 'react';

const ConfirmDelete = ({ id, type, text, handleDelete }) => {
	return (
		<div id={id} className="modal confirmation-modal confirmation-modal-delete " tabIndex="-1" role="dialog">
			<div className="modal-dialog modal-sm" role="document">
				<div className="modal-content">
					<div className="modal-header bg-danger text-white">
						<h6 className="modal-title">
							<i className="fas fa-question-circle mr-2" /> Confirm Delete
						</h6>
						<button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<p>Are you sure you want to permanently delete this {type.toLowerCase()}?</p>
						<p>{text}</p>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">
							Cancel
						</button>

						<button type="submit" className="btn btn-sm btn-danger" onClick={handleDelete}>
							Yes, Delete it!
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmDelete;
