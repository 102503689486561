const initState = {
	events: [],
	error: '',
	filter: '',
	isLoading: true,
	next_page: ''
};

const eventReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_EVENTS_REQUEST':
			return {
				...state,
				events: [],
				error: '',
				isLoading: true
			};

		case 'FETCH_EVENTS_NXTRQST':
			return {
				...state,
				error: '',
				isLoading: true
			};

		case 'FETCH_EVENTS_SUCCESS':
			return {
				...state,
				events: action.payload.data,
				error: '',
				isLoading: false,
				next_page: action.payload.next_page_url,
				filter: action.payload.filter
			};

		case 'FETCH_EVENTS_NXTPAGE':
			return {
				...state,
				events: [ ...state.events, ...action.payload.data ],
				error: '',
				isLoading: false,
				next_page: action.payload.next_page_url,
				filter: action.payload.filter
			};

		case 'FETCH_EVENTS_FAILURE':
			return {
				...state,
				error: action.payload,
				isLoading: false
			};

		default:
			return state;
	}
};

export default eventReducer;
