const config = {
	publicURL: 'https://withinph.com/',
	baseAPIURL: 'https://admin.withinph.com/api/',
	baseIMGURL: 'https://admin.withinph.com/storage/',
	// publicURL: 'https://localhost:3000/',
	// baseAPIURL: 'https://withinph_admin.test/api/',
	// baseIMGURL: 'https://withinph_admin.test/storage/'
};

export default config;
