const initState = {
	city: {},
	error: '',
	loading: true
};

const cityDetailReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_CITYDETAILS_REQUEST':
			return {
				city: {},
				error: '',
				loading: true
			};

		case 'FETCH_CITYDETAILS_SUCCESS':
			return {
				city: action.payload,
				error: '',
				loading: false
			};

		case 'FETCH_CITYDETAILS_FAILURE':
			return {
				city: {},
				error: action.payload,
				loading: false
			};

		default:
			return state;
	}
};

export default cityDetailReducer;
