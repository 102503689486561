import React from 'react';
import { Link } from 'react-router-dom';

const CityBreadcrumbs = ({ city }) => {

	return (
		<section className="middle-item-path d-none d-sm-block">
			<ol className="breadcrumb" aria-label="breadcrumb">
				<li className="breadcrumb-item"><Link to="/">Home</Link></li>
				<li className="breadcrumb-item">{city.island}</li>
				<li className="breadcrumb-item">{city.region_byname}</li>
				<li className="breadcrumb-item"><Link to={`/${city.province_slug}/`}>{city.province_name}</Link></li>
				<li className="breadcrumb-item">{city.name}</li>
			</ol>
			<Link to="/" className="item-breads-button btn-borderless d-none d-sm-block">
				<i className="fas fa-times" />
			</Link>
		</section>
	);
};

export default CityBreadcrumbs;
