import axios from 'axios';
import config from '../../utils/config';

export const fetchProvincesSuccess = (data) => {
	return {
		type: 'FETCH_PROVINCES_SUCCESS',
		payload: data
	};
};

/*
** Fetch List of Provinces
*/
export const fetchProvinces = () => {
	let url = config.baseAPIURL + 'provinces';
	let hdr = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				dispatch(fetchProvincesSuccess(response.data));
			})
			.catch((error) => {});
	};
};
