import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import { useForm } from '../../utils/hooks';
import { createReview } from '../../redux/actions';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

import StarsInput from '../StarRatingInput';

const ReviewForm = ({ city }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [ dates, setDates ] = useState({ date_start: null, date_end: null });
	const [ error, setError ] = useState('');
	const [ loading, setLoading ] = useState(false);

	const srvrError = useSelector((state) => state.review.actionError);
	const isLoading = useSelector((state) => state.review.actionLoading);

	const handleCreateReview = () => {
		setLoading(true);

		if (dates.date_start && dates.date_end && dates.date_start > dates.date_end) {
			setLoading(false);
			setError('Invalid Trip Dates.');
		} else {
			const params = { ...values, ...dates, city_id: city.id, city_name: city.name, city_slug: city.slug, province_slug: city.province_slug };
			dispatch(createReview(params, history));
		}
	};

	const { values, formError, handleChange, handleSubmit } = useForm(
		{
			review: '',
			score: 0,
			fun: 0,
			attractions: 0,
			safety: 0,
			serenity: 0,
			nightlife: 0,
			accessibility: 0,
			accommodations: 0,
			activities: 0,
			festivals: 0,
			eateries: 0,
			shops: 0,
			friendliness: 0,
			helpfulness: 0
		},
		handleCreateReview,
		false
	);

	const categories = [
		'fun',
		'attractions',
		'safety',
		'serenity',
		'nightlife',
		'accessibility',
		'accommodations',
		'activities',
		'festivals',
		'eateries',
		'shops',
		'friendliness',
		'helpfulness'
	];

	/*
	** manages form error validation results
	** from front-end validation and server
	*/
	useEffect(
		() => {
			if (srvrError) {
				setLoading(false);
				setError(srvrError);
			}

			if (formError) {
				setLoading(false);
				setError(formError);
			}
		},
		[ formError, srvrError, isLoading ]
	);

	return (
		<div className="ReviewForm">
			<form onSubmit={handleSubmit}>
				{error && <Message message={error} type="alert-danger" />}

				<div className="form-group form-group-score">
					<label>
						<strong>Give Your Overall Score:</strong>
					</label>
					<StarsInput value={values.score} handleChange={handleChange} text="score" />
				</div>

				<div className="reviewform-insight">
					<div
						className="reviewform-insight-title collapsed"
						data-toggle="collapse"
						href="#reviewInsight"
						role="button"
						aria-expanded="false"
						aria-controls="reviewInsight"
					>
						<div>
							<h5 className="content-title-collapse">
								<span className="d-none d-sm-inline">Want to give a better insight? (optional)</span>
							</h5>
						</div>
					</div>

					<div id="reviewInsight" className="collapse">
						<div className="form-group">
							<label htmlFor="review">Leave a Review</label>
							<textarea
								className="form-control"
								id="review"
								name="review"
								rows="3"
								onChange={handleChange}
								value={values.review}
							/>
						</div>

						<div className="form-row">
							{categories.map((category) => (
								<div key={`rating-${category}`} className="col-12 col-sm-6 col-md-3">
									<div className="form-group">
										<label className="reviewform-stars-label">
											{category.charAt(0).toUpperCase() + category.slice(1)}
										</label>
										<StarsInput
											value={values[category]}
											handleChange={handleChange}
											text={category}
											small={true}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="reviewform-trip">
					<label className="m-0">
						<i className="fas fa-hiking" /> Add as a Trip on your Profile!
					</label>
					<small className="d-block mb-2">
						Fill out the dates and this review will automatically be added as a trip on your profile.
					</small>
					<span className="d-block">Date of Visit:</span>
					<div className="form-inline">
						
						<div className="input-group input-group-sm mr-2 mb-2">
							<div className="input-group-prepend">
								<span className="input-group-text">Start</span>
							</div>

							<DatePicker
								id="date_start"
								name="date_start"
								className="form-control form-control-sm"
								autoComplete="off"
								selected={dates.date_start}
								onChange={(date) => setDates({ ...dates, date_start: date })}
								dateFormat="yyyy/MM/dd"
								maxDate={new Date()}
								scrollableMonthYearDropdown
							/>
						</div>

						<div className="input-group input-group-sm mb-2">
							<div className="input-group-prepend">
								<span className="input-group-text">End</span>
							</div>

							<DatePicker
								id="date_end"
								name="date_end"
								className="form-control form-control-sm"
								autoComplete="off"
								selected={dates.date_end}
								onChange={(date) => setDates({ ...dates, date_end: date })}
								dateFormat="yyyy/MM/dd"
								minDate={dates.date_start}
								maxDate={new Date()}
								scrollableMonthYearDropdown
							/>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-end">
					<div className="d-flex justify-content-center justify-content-md-end">
						<Link
							to={`/${city.province_slug}/${city.slug}`}
							className="btn btn-danger btn-cancel mr-2"
						>
							Cancel
						</Link>
						{loading ? (
							<button className="btn btn-gray" type="submit" disabled>
								<Loading />
							</button>
						) : (
							<button className="btn btn-gray" type="submit">
								Submit Your Review
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default ReviewForm;
