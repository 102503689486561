import React, { Component } from 'react';

class HelpHead extends Component {
	render() {
		const email = 'help&commat;withinph&period;com';

		return (
			<div className="content-title">
				<div className="row no-gutters">
					<div className="col-12 col-sm-6">
						<h1>Help Page</h1>
					</div>
					<div className="col-12 col-sm-6">
						If you don't see your question below,<br />drop us an email on:{' '}
						<span dangerouslySetInnerHTML={{ __html: email }} />
					</div>
				</div>
			</div>
		);
	}
}

export default HelpHead;
