const initState = {
	cities: [],
	error: '',
	filter: '',
	isLoading: true,
	next_page: ''
};

const cityReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_CITIES_REQUEST':
			return {
				...state,
				cities: [],
				error: '',
				isLoading: true
			};

		case 'FETCH_CITIES_NXTRQST':
			return {
				...state,
				error: '',
				isLoading: true
			};

		case 'FETCH_CITIES_SUCCESS':
			return {
				...state,
				cities: action.payload.data,
				error: '',
				isLoading: false,
				next_page: action.payload.next_page_url,
				filter: action.payload.filter
			};

		case 'FETCH_CITIES_NXTPAGE':
			return {
				...state,
				cities: [ ...state.cities, ...action.payload.data ],
				error: '',
				isLoading: false,
				next_page: action.payload.next_page_url,
				filter: action.payload.filter
			};

		case 'FETCH_CITIES_FAILURE':
			return {
				...state,
				error: action.payload,
				isLoading: false
			};

		default:
			return state;
	}
};

export default cityReducer;
