const initState = {
	loading: false,
    faqs:    '',
    error:   '',
};

const helpReducer = (state = initState, action) => {
	switch (action.type) {
        case 'FETCH_HELP_REQUEST':
            return {
                ...state,
                faqs:      '',
                error:     '',
                loading: true,
            };

        case 'FETCH_HELP_SUCCESS':
			return {
                faqs:    action.payload,
				error:     '',
				loading: false,
            };
            
        case 'FETCH_HELP_FAILURE':
            return {
                ...state,
                faqs:    '',
				error:   action.payload,
				loading: false,
            };

		default:
			return state;
	}
};

export default helpReducer;
