import React from 'react';
import { Link } from 'react-router-dom';

import { attachUTMCodeOnLink } from '../utils/helpers';
import config from '../utils/config';
import DefaultAvatar from '../assets/images/user.png';

const ProfileHeader = ({ profile }) => {
	const name = profile.firstname.trim() ? profile.firstname + ' ' + profile.lastname : '@' + profile.username;
	const link = profile.hometown ? '/' + profile.hometown.province_slug + '/' + profile.hometown.city_slug : '';
	const home = profile.hometown ? profile.hometown.city_name + ', ' + profile.hometown.province_name : '';

	return (
		<section className="middle-user-head">
			<div className="card">
				<div className="row">
					<div className="col-md-3 col-lg-2  mb-3 mb-md-0">
						<img
							className=""
							src={profile.image ? config.baseIMGURL + profile.image : DefaultAvatar}
							alt={'@' + profile.username}
						/>
					</div>

					<div className="col-md-9 col-lg-10">
						<div className="card-body">
							<h1 className="card-title">{name}</h1>

							{home && (
								<p className="card-text middle-user-head-home">
									<i className="fas fa-map-marker-alt" />
									<Link to={link}>{home}</Link>
								</p>
							)}

							{profile.biography && (
								<p className="card-text middle-user-head-desc">{profile.biography}</p>
							)}

							{profile.website && (
								<p className="card-text middle-user-head-home">
									<i className="fas fa-globe" />
									<a
										href={attachUTMCodeOnLink(profile.website)}
										target="_blank"
										rel="nofollow noopener noreferrer"
									>
										{profile.website}
									</a>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProfileHeader;
