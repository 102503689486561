import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { fetchPage } from '../redux/actions';
import { formatStrSentenceCase } from '../utils/helpers/';

import MetaTag from '../components/MetaTags';
import Sidebar from '../containers/PageSidebar';
import Content from '../containers/PageContent';

const Page = () => {
	const dispatch = useDispatch();
	const urlPages = useLocation();
	const pageType = urlPages.pathname.replace(/\//g, '');

	const pageDetail = useSelector((state) => state.page);

	/* 
	** didmount useeffect
	** fetch page details
	*/
	useEffect(
		() => {
			if (pageDetail.pages[pageType] === undefined) {
				dispatch(fetchPage(pageType));
			}
		},
		[ pageType ]
	);

	const data = {
		title: pageDetail.pages[pageType] ? pageDetail.pages[pageType].title : formatStrSentenceCase(pageType),
		content: pageDetail.pages[pageType] ? pageDetail.pages[pageType].content : '',
		slug: pageType
	};

	return (
		<section className="middle-page-main">
			<MetaTag data={data} type="page" follow={true} />
			<div className="row">
				<div className="col-md-8 order-md-1">
					<Content details={pageDetail} type={pageType} />
				</div>
				<div className="col-md-4 order-md-2">
					<Sidebar />
				</div>
			</div>
		</section>
	);
};

export default Page;
