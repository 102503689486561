import React, { useState } from 'react';
import moment from 'moment';

import { formatStrSentenceCase } from '../utils/helpers';
import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

const EventCard = ({ event, handleVotes, auth }) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ image, setImage ] = useState(config.baseIMGURL + event.image || DefaultImage);

    /*
	** Trigger when Place Up or Down Button
	** are clicked
	*/
	const handleVote = (e) => {
		const vote = e.target.value;
		handleVotes(event.id, vote);
    };
    console.log(event.votes);
    const vote = event.votes && event.votes.length > 0 ? Boolean(parseInt(event.votes[0].vote)) : null;

    const renderEventDate = event.date_texts ? (formatStrSentenceCase(event.date_texts)) : event.date_end 
                            ? (moment(event.date_start).format('MMM Do') + ' - ' + moment(event.date_end).format('MMM Do')) 
							: (moment(event.date_start).format('MMM Do'));

	const rendersContents = (event?.description || "No Information Found.") 
						  +	(event.image_credit 
								? " Photo Credit to " + event.image_credit + "."
								: "") 
						  +	(event.website 
								? (<>You can visit their <a href={event.website} target="_blank" rel="nofollow noopener noreferrer">website</a> for more information.</>)
								: "");     
	return (
		<li className={`list-group-item d-flex justify-content-between lh-condensed ${expanded && 'lists-events-expand'}`}>
			<div className="media">
                <img
					className="item-middle-list-img d-none d-sm-inline"
					src={image}
                    alt={event.name}
                    onError={() => setImage(DefaultImage)}
				/>
				<div className="media-body item-middle-list-txt">
					<h6 className="m-0 font-weight-bold">{event.name}</h6>
					<span className="list-group-taxonomy">
						<i className="mr-1 fas fa-map-signs" />
						{event.location}
						<i className="ml-2 mr-1 fas fa-calendar-day" />
						{renderEventDate}
					</span>
					<span className="list-group-descript">
                        {rendersContents}
                        {(rendersContents.replace(/<\/?[^>]+(>|$)/g, "").length > 150) && 
                            <button className="ml-1 btn btn-sm btn-borderless" onClick={() => setExpanded(!expanded)} />
                        }
					</span>
				</div>
				<div className="content-lists-vote-btn">
					<div className="list-vote-love">
						{auth ? (
							<button
								value="1"
								data-type='event'
								className={vote !== null && vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
					<div className="list-vote-text">{event.total_votes}</div>
					<div className="list-vote-hate">
						{auth ? (
							<button
								value="0"
								data-type='event'
								className={vote !== null && !vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && !vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
				</div>
			</div>
		</li>
	);
};

export default EventCard;
