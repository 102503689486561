/*
** Convert Parameters
** for URL (query and display)
**  
** Parameter: Object Parameter Details
** Return: String
*/
export function formatQueryParameters(params) {
	let newParams = '';

	// filter
	if (params.filter) {
		newParams += `&filter=${params.filter}`;
	}

	// months
	if (params.months) {
		newParams += `&month=${params.months}`;
	}

	// sortby
	if (params.sortby !== '' && params.sortby !== 'score') {
		newParams += `&sortby=${params.sortby}`;
	}

	return newParams;
}
