import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import axios from 'axios';

import config from '../utils/config';

import CityEvent from './CityEventItem';
import AttractionModal from '../components/modals/AttractionDetails';
import Message from '../components/messages/DisplayMessage';

const CityEventList = ({ city }) => {
	const auth = useSelector((state) => state.auth.isAuthenticated);
	const [ events, setEvents ] = useState(null);
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState('');
	const [ sort, setSort ] = useState('vote');
	const [ modalDetails, setModalDetails ] = useState({});

	useEffect(
		() => {
			if (city) {
				handleFetch(city.id);
			}
		},
		[ city, sort ]
	);

	const handleSort = (e) => {
		setSort(e.target.value);
	};

	/*
	** Fetch List of Events
	*/
	const handleFetch = (id) => {
		const apiURL = config.baseAPIURL + 'events/' + id;

		let params = { sortby: sort };

		if (auth) {
			params.user_id = JSON.parse(localStorage.withinPHToken).id;
		}

		setLoading(true);

		axios
			.get(apiURL, { params: params })
			.then((response) => {
				setEvents(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setError('Error Fetching Events');
				setLoading(false);
			});
	};

	/*
	** Cast Votes
	*/
	const handleVotes = (id, vote) => {
		const userid = JSON.parse(localStorage.withinPHToken).id;
		const tokens = JSON.parse(localStorage.withinPHToken).data;
		const apiURL = config.baseAPIURL + 'vote/event/' + id;
		const params = { vote: vote, user_id: userid };
		const header = {
			Accept: 'application/json',
			Authorization: `Bearer ${tokens}`
		};

		axios
			.put(apiURL, params, { headers: header })
			.then((response) => {
				toast.success('Vote added successfully.', { autoClose: 2000 });
				handleFetch(city.id);
			})
			.catch((error) => {
				toast.error('Error adding vote. Try again.', { autoClose: 2000 });
			});
	};

	const handleModal = (params) => {
		setModalDetails(params);
		$('#EventDetails').modal('show');
	};

	return (
		<React.Fragment>
			<div className="content-title">
				<div className="row no-gutters">
					<div className="col-12 col-sm-6">
						<h6>Festivals</h6>
					</div>
					<div className="col-12 col-sm-6">
						<div className="input-group input-group-sm">
							<label className="input-group-text">Sort By</label>
							<select className="custom-select" value={sort} onChange={handleSort}>
								<option value="vote">Votes</option>
								<option value="name">Names</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="content-texts">
				<p>
					Here are the list of events or festivals you can experience in {city.name}, {city.province_name}{' '}
					Philippines. Help others by casting your vote on which of these events or festivals are worth seeing
					by either up-voting or down-voting. Contact us if you want to add more in this list.
				</p>
			</div>

			{/* Errors */}
			{error && !loading && <Message message={error} />}

			{events &&
				(events.length > 0 ? (
					<ul className="list-group content-lists content-lists-vote  lists-events">
						{events.map((event) => (
							<li
								key={`EVENT-${event.id}`}
								className="list-group-item d-flex justify-content-between lh-condensed"
							>
								<CityEvent
									event={event}
									handleVotes={handleVotes}
									handleModal={handleModal}
									auth={auth}
								/>
							</li>
						))}
					</ul>
				) : (
					!error && <Message message="No Events Found Yet" />
				))}

			{events && events.length > 0 && <AttractionModal id="EventDetails" attraction={modalDetails} />}
		</React.Fragment>
	);
};

export default CityEventList;
