import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { userLogout } from '../redux/actions';
import config from '../utils/config';

import DefaultAvatar from '../assets/images/user.png';
import SearchBar from '../components/forms/SearchHeader';

const SiteHeader = ({ ...props }) => {
	const dispatch = useDispatch();

	const userAuth = useSelector((state) => state.auth.user);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const [ isLoggedIn, setIsLoggedIn ] = useState(false);
	const [ userDetail, setUserDetail ] = useState({ ...userAuth });

	useEffect(
		() => {
			setIsLoggedIn(isAuthenticated);
			setUserDetail({ ...userAuth });
		},
		[ isAuthenticated, userAuth ]
	);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(userLogout());
	};

	const guestsNav = (
		<ul className="navbar-nav">
			<li className="nav-item px-2">
				<Link
					to="/auth/signin"
					className="nav-link navCTA navCTABorder"
					data-toggle="modal"
					data-target="#signInModal"
					aria-expanded={isAuthenticated}
				>
					Login
				</Link>
			</li>
			<li className="nav-item d-none d-sm-block">
				<Link
					to="/auth/signup"
					className="nav-link navCTA navCTAShades"
					data-toggle="modal"
					data-target="#signUpModal"
					aria-expanded={isAuthenticated}
				>
					Join
				</Link>
			</li>
		</ul>
	);

	const memberNav = (
		<ul className="navbar-nav">
			<li className="nav-item dropdown show">
				<Link
					className="nav-link dropdown-toggle user-nav-dropdown"
					id="UserDropdown"
					to="/"
					role="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="true"
				>
					{userDetail && (
						<img
							className="card-img"
							src={userDetail.image ? config.baseIMGURL + userDetail.image : DefaultAvatar}
							alt={userDetail.username}
						/>
					)}
					{userDetail && <span className="dropdown-username">{userDetail.username}</span>}
				</Link>
				<div className="dropdown-menu dropdown-menu-right fade" aria-labelledby="UserDropdown">
					{userDetail && (
						<Link className="dropdown-item" to={`/u/${userDetail.username}`}>
							Profile
						</Link>
					)}
					<Link className="dropdown-item" to="/settings">
						Account
					</Link>
					<div className="dropdown-divider" />
					<Link className="dropdown-item" to="/help">
						Help
					</Link>
					<div className="dropdown-divider" />
					<Link className="dropdown-item" to="/logout" onClick={handleLogout}>
						Log Out
					</Link>
				</div>
			</li>
		</ul>
	);

	const navLinks = !(props.location.pathname.includes('signin') || props.location.pathname.includes('signup'))
		? isLoggedIn ? memberNav : guestsNav
		: '';

	return (
		<header className="navbar navbar-expand navbar-dark flex-column flex-sm-row fixed-top justify-content-end">
			<div className="d-flex justify-content-between">
				<Link className="navbar-brand" to="/">
					Within<span>PH</span>
				</Link>

				<div className="d-inline d-sm-none" id="headersNavbar">
					{navLinks}
				</div>
			</div>
			<SearchBar />
			<div className="d-none d-sm-inline flex-grow-0" id="headersNavbar">
				{navLinks}
			</div>
		</header>
	);
};

export default withRouter(SiteHeader);
