import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Map from '../components/Map';
import Loading from '../components/loading/BouncingDots';

const ProfileMap = () => {
	let arrProv = [],
		arrCity = [];
	const [ counters, setCounters ] = useState({ city: 0, province: 0, trips: 0, percentage: 0 });

	const isLoading = useSelector((state) => state.trip.fetchLoading);
	const trips = useSelector((state) => state.trip.trips);

	useEffect(
		() => {
			if (trips) {
				let cCtr = 0;
				let pCtr = 0;
				let prct = 0;

				trips.forEach((trip) => {
					arrProv.push(trip.province_slug);
					arrCity.push(trip.city_slug + '-' + trip.province_slug);
				});

				cCtr = new Set(arrCity).size;
				pCtr = new Set(arrProv).size;
				prct = cCtr / 1634 * 100;

				setCounters({
					city: cCtr,
					province: pCtr,
					trips: trips ? trips.length : 0,
					percentage: parseInt(prct) === 0 ? Math.round((prct + Number.EPSILON) * 100) / 100 : parseInt(prct)
				});
			}
		},
		[ trips ]
	);

	return (
		<div className="middle-user-main-status">
			<div className="content-title">
				<div>
					<h6>Status</h6>
				</div>
			</div>

			{isLoading ? (
				<Loading text="calibrating a map" />
			) : (
				<React.Fragment>
					<div className="middle-user-main-stats-num">
						<div className="row">
							<div className="col-12 col-sm-3">
								<span>{counters.trips}</span>
								<strong>Trips </strong>
							</div>
							<div className="col-12 col-sm-3">
								<span>{counters.percentage}%</span>
								<strong>of PH </strong>
							</div>
							<div className="col-12 col-sm-3">
								<span>{counters.province}</span>
								<strong>Provinces</strong>
							</div>
							<div className="col-12 col-sm-3">
								<span>{counters.city}</span>
								<strong>Cities </strong>
							</div>
						</div>
					</div>

					<div className="middle-user-main-stats-map">
						<Map trips={trips} />
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default ProfileMap;
