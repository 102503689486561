import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SearchQueryToArrays, formatQueryParameters } from '../utils/helpers';
import { fetchCities, fetchMoreCities } from '../redux/actions/';

import Listing from '../containers/CitiesListing';
import Filters from '../containers/CitiesFilters';
import MetaTag from '../components/MetaTags';
import Message from '../components/messages/DisplayMessage';
import AlertMg from '../components/messages/AlertMessage';
import Loading from '../components/loading/BouncingDots';

const List = ({ ...props }) => {
	const dispatch = useDispatch();
	const urlQuery = useLocation();
	const urlParam = useParams();

	const cityData = useSelector((state) => state.city);
	
	const [ hasError, setHasError ] = useState('');
	const [ filters, setFilters ]   = useState({
		province: urlParam.province || '',
		filter: SearchQueryToArrays(urlQuery.search).hasOwnProperty('filter')
			? SearchQueryToArrays(urlQuery.search).filter
			: '',
		sortby: SearchQueryToArrays(urlQuery.search).sortby || ''
	});

	const secMount = React.useRef(false);
	const metadata =
		filters.province !== ''
			? { name: `${filters.province.replace(/-/gi, ' ')}`, slug: filters.province, type: 'province' }
			: { type: 'home' };

	/* 
	** didmount useeffect
	** fetch list of cities
	*/
	useEffect(() => {
		/*
		** fetch list of cities
		** this will aggregate filter data first then
		** call dispatch to fetch the list
		*/
		const urlParameter = getURLParam().replace('&', '?');
		if (shouldFetch(urlParameter)) {
			dispatch(fetchCities(null, urlParameter));
		}
	}, []);

	/*
	** handle error when fetching cities 
	** and re-create scroll event listener 
	** everytime city data is updated
	*/
	useEffect(
		() => {
			// handle errors
			if (cityData.error) {
				// set server error
				setHasError(cityData.error);
			} else {
				if (!cityData.cities.length && !cityData.isLoading && !cityData.error) {
					setHasError('Oops! No results matched your current filter.');
				} else {

					// condition to make sure that scroll event is added only if cities are already loaded
					if(Object.keys(cityData.cities).length > 0) {
						// add scroll event listener
						window.addEventListener('scroll', handleScroll);
						return () => window.removeEventListener('scroll', handleScroll);
					}
					setHasError('');
				}
			}
		},
		[ cityData ]
	);

	/*
	** call fetch new set of cities
	** everytime url params are updated
	*/
	useEffect(
		() => {

			// condition is used to prevent this from running on first mount
			if(secMount.current && !cityData.isLoading) {
				let qryParams = '';
				let newFilter = {
					province: urlParam.province || '',
					filter: SearchQueryToArrays(urlQuery.search).hasOwnProperty('filter')
						? SearchQueryToArrays(urlQuery.search).filter
						: '',
					sortby: SearchQueryToArrays(urlQuery.search).sortby || ''
				};

				if (newFilter.province !== '') {
					qryParams += `&province=${newFilter.province}`;
				}
				qryParams += formatQueryParameters(newFilter);
				qryParams = qryParams.replace('&', '?');

				setFilters(newFilter);
				const urlParameter = getURLParam(newFilter).replace('&', '?');
				if(shouldFetch(urlParameter)) {
					dispatch(fetchCities(null, qryParams));
				}
				 
			} else { secMount.current = true; }
		},
		[ urlParam, urlQuery ]
	);

	/*
	** unmount useeffect
	** remove scroll event when component unmounts
	*/
	useEffect(() => {
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	/*
	** handle scroll events 
	** will detect scroll to the bottom of the page
	** then load more cities if next page is still available
	*/
	const handleScroll = () => {
		let isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

		// check if state is loading AND if there's more page to load
		if (!cityData.isLoading && cityData.next_page && isBottom) {
			const urlParam = getURLParam();

			// fetch more cities
			dispatch(fetchMoreCities(cityData.next_page + urlParam, urlParam.replace('&', '?')));
		}
	};

	const getURLParam = (f = filters) => {
		let params = '';

		if (f.province !== '') {
			params += '&province=' + f.province;
		}

		if (f.filter !== '' || f.sortby !== '') {
			params += formatQueryParameters(f);
		}

		return params;
	};

	/*
	** Use to check if fetching of cities is necessary 
	** It will return true if the list of city is empty 
	** or if saved filter is different from current filter
	*/
	const shouldFetch = (urlParameter) => {
		return Object.keys(cityData.cities).length === 0 || urlParameter !== cityData.filter;
	};

	return (
		<React.Fragment>
			{/* Meta Tags */}
			<MetaTag data={metadata} type={metadata.type} follow={true} />

			{/* Alerts */}
			{props.location.state && (
				<AlertMg type={props.location.state.alert.type} message={props.location.state.alert.message} />
			)}

			{/* Filter */}
			<Filters />

			{/* Errors */}
			{hasError && <Message message={hasError} />}

			{/* Cities */}
			{Object.keys(cityData.cities).length > 0 && <Listing cities={cityData.cities} />}

			{/* Loader */}
			{cityData.isLoading && (
				<Loading text={Object.keys(cityData.cities).length > 0 ? 'loading more cities' : 'loading cities'} />
			)}
		</React.Fragment>
	);
};

export default List;
