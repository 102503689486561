/*
** Convert City Details
** to City Overview
**  
** Parameter: Object City Details
** Return: String Overview
*/
export function createCityOverviews(details) {
	let oldname = details.byname ? ', previously know as ' + details.byname + ',' : '';
	return `${details.name} ${oldname} is a ${details.class} class ${details.type} in the Province of ${details.province_name},	Philippines located in the island group of ${details.island} and within ${details.region_name} - ${details.region_byname}. The ${details.type} covers a total area of ${details.area_size} square kilometers.`;
}
