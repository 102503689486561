import React, { Component } from 'react';

import FormSignIn from '../forms/AuthSignIn';

class AuthSignIn extends Component {
	render() {
		return (
			<div className="modal fade auth-modal" id="signInModal" tabIndex="-1" role="dialog">
				<div className="modal-dialog modal-dialog-centered modal-sm" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Welcome Back</h4>
						</div>
						<div className="modal-body">
							<FormSignIn />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AuthSignIn;
