import React, { Component } from 'react';

class Ellipsis extends Component {
	render() {
		return (
			<div className="ellipsis">
				{this.props.text && <span>{this.props.text}</span>}
				<span className="one">.</span>
				<span className="two">.</span>
				<span className="tri">.</span>
			</div>
		);
	}
}

export default Ellipsis;
