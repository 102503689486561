/*
** Add UTM Codes 
** on Outgoing Links
**  
** Parameter: String Links
** Return: String Links with UTM
*/
export function attachUTMCodeOnLink(link) {
	return `${link}?utm_source=withinph.com`;
}
