import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import config from '../utils/config';
import { fetchEvents, fetchMoreEvents } from '../redux/actions';
import { SearchQueryToArrays, formatQueryParameters } from '../utils/helpers';

import EventsFilters from '../containers/EventsFilters';
import EventsListing from '../containers/EventsListing';

import MetaTag from '../components/MetaTags';
import Message from '../components/messages/DisplayMessage';
import Loading from '../components/loading/BouncingDots';

const Event = () => {
	const dispatch = useDispatch();
	const urlParam = useParams();
	const urlQuery = useLocation();
	const festival = useSelector((state) => state.event);
	const authuser = useSelector((state) => state.auth.isAuthenticated);

	const [ hasError, setHasError ] = useState('');
	const [ filters, setFilters ]   = useState({
		province: urlParam.province || '',
		months: SearchQueryToArrays(urlQuery.search).hasOwnProperty('month')
			? SearchQueryToArrays(urlQuery.search).month
			: '',
		sortby: SearchQueryToArrays(urlQuery.search).sortby || ''
	});

	const secMount = React.useRef(false);

	/* 
	** didmount useeffect
	** fetch list of events
	*/
	useEffect(() => {
		/*
		** fetch list of events
		** this will aggregate filter data first then
		** call dispatch to fetch the list
		*/
		const urlParameter = getURLParam().replace('&', '?');
		if (shouldFetch(urlParameter)) {
			dispatch(fetchEvents(null, urlParameter, authuser));
		}
	}, []);

	/*
	** handle error when fetching events 
	** and re-create scroll event listener 
	** everytime events data is updated
	*/
	useEffect(
		() => {
			// handle errors
			if (festival.error) {
				// set server error
				setHasError(festival.error);
			} else {
				if (!festival.events.length && !festival.isLoading && !festival.error) {
					setHasError('Oops! No results matched your current filter.');
				} else {

					// condition to make sure that scroll event is added only if events are already loaded
					if(Object.keys(festival.events).length > 0) {
						// add scroll event listener
						window.addEventListener('scroll', handleScroll);
						return () => window.removeEventListener('scroll', handleScroll);
					}
					setHasError('');
				}
			}
		},
		[ festival ]
	);

	/*
	** call fetch new set of events
	** everytime url params are updated
	*/
	useEffect(
		() => {

			// condition is used to prevent this from running on first mount
			if(secMount.current && !festival.isLoading) {
				let qryParams = '';
				let newFilter = {
					province: urlParam.province || '',
					months: SearchQueryToArrays(urlQuery.search).hasOwnProperty('month')
						? SearchQueryToArrays(urlQuery.search).month
						: '',
					sortby: SearchQueryToArrays(urlQuery.search).sortby || ''
				};

				if (newFilter.province !== '') {
					qryParams += `&province=${newFilter.province}`;
				}
				qryParams += formatQueryParameters(newFilter);
				qryParams = qryParams.replace('&', '?');

				setFilters(newFilter);
				const urlParameter = getURLParam(newFilter).replace('&', '?');
				if(shouldFetch(urlParameter)) {
					dispatch(fetchEvents(null, qryParams, authuser));
				}
				 
			} else { secMount.current = true; }
		},
		[ urlParam, urlQuery ]
	);

	/*
	** unmount useeffect
	** remove scroll event when component unmounts
	*/
	useEffect(() => {
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	/*
	** handle scroll events 
	** will detect scroll to the bottom of the page
	** then load more events if next page is still available
	*/
	const handleScroll = () => {
		let isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

		// check if state is loading AND if there's more page to load
		if (!festival.isLoading && festival.next_page && isBottom) {

			console.log("yeah");
			const urlParam = getURLParam();

			// fetch more events
			dispatch(fetchMoreEvents(festival.next_page + urlParam, urlParam.replace('&', '?'), authuser));
		}
	};

	const getURLParam = (f = filters) => {
		let params = '';

		if (f.province !== '') {
			params += '&province=' + f.province;
		}

		if (f.filter !== '' || f.sortby !== '') {
			params += formatQueryParameters(f);
		}

		return params;
	};

	/*
	** Cast Votes
	*/
	const handleVotes = (id, vote) => {
		const userid = JSON.parse(localStorage.withinPHToken).id;
		const tokens = JSON.parse(localStorage.withinPHToken).data;
		const apiURL = config.baseAPIURL + 'vote/event/' + id;
		const params = { vote: vote, user_id: userid };
		const header = {
			Accept: 'application/json',
			Authorization: `Bearer ${tokens}`
		};

		axios
			.put(apiURL, params, { headers: header })
			.then((response) => {
				const urlParameter = getURLParam().replace('&', '?');
				toast.success('Vote added successfully.', { autoClose: 2000 });
				dispatch(fetchEvents(null, urlParameter, authuser));
			})
			.catch((error) => {
				toast.error('Error adding vote. Try again.', { autoClose: 2000 });
			});
	};

	/*
	** Use to check if fetching of events is necessary 
	** It will return true if the list of city is empty 
	** or if saved filter is different from current filter
	*/
	const shouldFetch = (urlParameter) => {
		return Object.keys(festival.events).length === 0 || urlParameter !== festival.filter;
	};

	return (
		<div className="EventPage">
			<div className="list-header">
				<h1>Festivals in the Philippines</h1>
				<p>
					The Philippines is known not only for its beautiful beaches and breathtaking sights, but also for
					its grand and vibrant Festivals. These annual celebrations give Filipinos a chance to exhibit their
					rich culture and pay homage to the history and their patron saints. Experience the colorful culture
					of the Philippines with these vibrant and big festivals around the country. Below you can find all
					the Philippines Festivals and some information about each event.
				</p>
			</div>

			<EventsFilters />

			{/* Errors */}
			{hasError && <Message message={hasError} />}

			{Object.keys(festival.events).length > 0 && <EventsListing events={festival.events} auth={authuser} handleVotes={handleVotes} />}

			{/* Loader */}
			{festival.isLoading && (
				<Loading text={Object.keys(festival.events).length > 0 ? 'loading more events' : 'loading events'} />
			)}
		</div>
	);
};

export default Event;
