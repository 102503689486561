import React from 'react';

import { attachUTMCodeOnLink } from '../../utils/helpers';
import config from '../../utils/config';
import DefaultImage from '../../assets/images/item.png';

const AttractionDetails = ({ id, attraction }) => {
	return (
		<div id={id} className="modal attraction-modal" tabIndex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="card">
							<div className="row no-gutters">
								<div className="col-sm-5">
									<div className="img-container">
										<img
											src={attraction.image ? config.baseIMGURL + attraction.image : DefaultImage}
											alt={attraction.name}
										/>
										<small><i className="fas fa-camera mr-1" /> {attraction.image_credit}</small>
									</div>
								</div>
								<div className="col-sm-7">
									<div className="card-block">
										<h5 className="card-title">{attraction.name}</h5>
										<span className="text-muted">{attraction.subtext}</span>
										{attraction.description && <p>{attraction.description}</p>}
										<ul className="list-inline">
											{attraction.tourism_fee && (
												<li>
													<strong>Fees:</strong> {attraction.tourism_fee}
												</li>
											)}
											{attraction.website && (
												<li>
													<a
														href={attachUTMCodeOnLink(attraction.website)}
														target="_blank"
														rel="nofollow noopener noreferrer"
													>
														Website
													</a>
												</li>
											)}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AttractionDetails;
