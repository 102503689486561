import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { createCityFootnotes } from '../utils/helpers';

import ProgressBar from '../components/ProgressBar';

class CityOverview extends Component {
	render() {
		const { city, user, isAuthenticated } = this.props;

		let ratings = city.average_ratings.length
			? { ...city.average_ratings[0] }
			: {
					score: 0,
					fun: 0,
					attractions: 0,
					safety: 0,
					serenity: 0,
					nightlife: 0,
					accessibility: 0,
					accommodations: 0,
					activities: 0,
					festivals: 0,
					eateries: 0,
					shops: 0,
					friendliness: 0,
					helpfulness: 0
				};

		delete ratings.city_id;
		delete ratings.review_count;

		return (
			<div>
				<div className="content-title">
					<div className="row no-gutters">
						<div className="col-12 col-sm-6">
							<h6>Overview</h6>
						</div>
						<div className="col-12 col-sm-6 d-flex justify-content-center d-sm-inline">
							{isAuthenticated ? user.is_verified ? (
								<Link
									to={`/review/${city.province_slug}/${city.slug}`}
									className="btn btn-sm btn-gray d-block d-sm-inline"
								>
									<i className="fas fa-star" /> Rate This City
								</Link>
							) : (
								<button
									className="btn btn-sm btn-gray d-block d-sm-inline"
									data-toggle="modal"
									data-target="#VerifyEmail"
								>
									<i className="fas fa-star" /> Rate This City
								</button>
							) : (
								<button
									className="btn btn-sm btn-gray d-block d-sm-inline"
									data-toggle="modal"
									data-target="#signInModal"
								>
									<i className="fas fa-star" /> Rate This City
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="content-score">
					<div className="row no-gutters">
						{Object.keys(ratings).map((key, index) => (
							<div key={key} className={index % 2 ? `col-12 col-md-6` : `col-12 col-md-6 pr-md-3`}>
								<div className="row">
									<div className="col-12 col-sm-4 col-md-6">
										<strong>
											{key === 'friendliness' || key === 'helpfulness' ? 'local ' + key : key}
										</strong>
									</div>
									<div className="col-12 col-sm-8 col-md-6">
										<ProgressBar score={ratings[key]} label={key} />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="content-texts footnotes">
					<p>{createCityFootnotes(city)}</p>
				</div>
			</div>
		);
	}
}

export default CityOverview;
