import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import $ from "jquery";

import SignIn from '../components/forms/AuthSignIn';
import SignUp from '../components/forms/AuthSignUp';
import Forgot from '../components/forms/AuthForgot';
import Resets from '../components/forms/AuthResets';

const Auth = () => {
	const urlPaths = useHistory();
	const urlParam = useParams();

    const [ maintitle, setMaintitle ] = useState("");
    const [ subtitles, setSubtitles ] = useState("");
    const [ authforms, setAuthforms ] = useState(null);

    useEffect(
		() => {
			switch(urlParam.type) {
                case "signin":
                    setMaintitle("SIGN IN");
                    setSubtitles("to start sharing your trips and rating cities.");
                    setAuthforms(<SignIn isPage={true} />);
                    break;
                case "signup":
                    setMaintitle("SIGN UP");
                    setSubtitles("to start sharing your trips and rating cities.");
                    setAuthforms(<SignUp isPage={true} />);
                    break;
                case "forgot":
                    setMaintitle("FORGOT PASSWORD");
                    setSubtitles("leave your email & we'll send you a link to reset your password.");
                    setAuthforms(<Forgot />);
                    $('.modal.show').modal('hide');
                    break;
                case "resets":
                    setMaintitle("CHANGE PASSWORD");
                    setSubtitles("your password must be at least 8 characters long.");
                    setAuthforms(<Resets />);
                    break;
                default: 
                    urlPaths.push("/");
            }
		},
		[ urlParam ]
	);

		return (
			<div className="row justify-content-center auth-pages">
				<div className="col-12 col-sm-8 col-md-6 col-lg-4">
                    <h2>{maintitle}</h2><h3>{subtitles}</h3><div>{authforms}</div>
				</div>
			</div>
		);
}

export default Auth;
