import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import config from '../../utils/config';

const SearchHeader = ({ ...props }) => {
	const history = useHistory();

	const [ query, setQuery ] = useState('');
	const [ results, setResults ] = useState([]);
	const [ select, setSelect ] = useState(-1);

	useEffect(
		() => {
			if (query.length >= 3) {
				handleSearch(query);
			} else {
				setResults([]);
			}
		},
		[ query ]
	);

	useEffect(() => {
		return () => {
			setSelect(-1);
		};
	}, []);

	// function to close the search result
	const handleClose = (event = null) => {
		if (event) {
			event.preventDefault();
		}
		setSelect(-1);
		setQuery('');
	};

	// function to close the search result
	// after redirection
	const handleClick = (event) => {
		handleClose();
	};

	// function to trigger api request
	const handleSearch = (query) => {
		const url = config.baseAPIURL + 'search/' + query;

		// fetch search query results
		axios
			.get(url)
			.then((response) => {
				setResults([ ...response.data ]);
			})
			.catch((error) => {});
	};

	// function to handle key actions on form
	const handleKeyAct = (e) => {
		if (e.keyCode === 13 && select >= 0) {
			// enter key
			handleClose();
			history.push(results[select].link);
		} else if (e.keyCode === 38 && select >= 0) {
			// up arrow key
			setSelect(select - 1);
		} else if (e.keyCode === 40 && select < results.length - 1) {
			// down arrow key
			setSelect(select + 1);
		}
	};

	return (
		<form className="form-inline with-icon with-icon-left mr-auto SearchBar">
			<i className="fas fa-search" />
			<input
				id="query"
				name="query"
				className="form-control form-control-dark"
				type="text"
				placeholder="Search"
				aria-label="Search"
				autoComplete="off"
				onChange={(e) => {
					setQuery(e.target.value);
					setSelect(-1);
				}}
				onKeyDown={handleKeyAct}
				value={query}
			/>
			{query !== '' || results.length > 0 ? (
				<button className="btn btn-borderless" onClick={handleClose}>
					<i className="fas fa-times-circle" />
				</button>
			) : (
				''
			)}
			{results.length > 0 ? (
				<ul className="SearchResults">
					{results.map((item, indx) => (
						<li
							key={indx}
							className={select === indx ? 'active' : null}
							onMouseEnter={() => setSelect(indx)}
						>
							<Link to={item.link} className="search-result" onClick={handleClick}>
								{item.text}
								<small>{item.subtext}</small>
							</Link>
						</li>
					))}
				</ul>
			) : query.length > 3 ? (
				<ul className="SearchResults">
					<li>No Results Found</li>
				</ul>
			) : (
				''
			)}
		</form>
	);
};

export default SearchHeader;
