import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const geoURL = require('../assets/data/philippines-cities.json');
const config = {
    scale: 2000,
	center: [ 121.774, 12.8797 ]
};

const App = ({trips}) => {
    let viewed = false;
	return (
		<ComposableMap 
			projection = "geoMercator" 
			projectionConfig = {config} 
			width  = {400}
			height = {600} 
			style  = {{ width: "100%", height: "auto" }}
		>
			<Geographies geography={geoURL}>
				{({ geographies }) =>
					geographies.map((geo) => {
                        viewed = trips && trips.find((s) => ((s.topo_id === geo.properties.ID_2)));
						return <Geography key={geo.rsmKey} geography={geo} fill={viewed ? '#33475b' : '#CCC'} />;
					})}
			</Geographies>
		</ComposableMap>
	);
};

export default App;