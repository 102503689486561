const initState = {
	deleteError: '',
	createError: '',
	actionLoading: false,
	fetchError: '',
	fetchLoading: false,
	trips: []
};

const tripReducer = (state = initState, action) => {
	switch (action.type) {
		case 'ACTION_TRIP_REQUEST':
			return {
				...state,
				createError: '',
				deleteError: '',
				actionLoading: true
			};

		case 'CREATE_TRIP_SUCCESS':
			return {
				...state,
				createError: '',
				deleteError: '',
				actionLoading: false
			};

		case 'DELETE_TRIP_SUCCESS':
			return {
				...state,
				createError: '',
				deleteError: '',
				actionLoading: false
			};

		case 'ACTION_TRIP_FAILURE':
			let createErr, deleteErr;

			switch (action.payload.type) {
				case 'create':
					createErr = action.payload.message;
					deleteErr = '';
					break;
				case 'delete':
					createErr = '';
					deleteErr = action.payload.message;
					break;
				default:
					createErr = '';
					deleteErr = '';
			}

			return {
				...state,
				actionError: action.payload,
				createError: createErr,
				deleteError: deleteErr,
				actionLoading: false
			};

		case 'FETCH_TRIPS_REQUEST':
			return {
				...state,
				trips: [],
				fetchError: '',
				fetchLoading: true
			};
		case 'FETCH_TRIPS_SUCCESS':
			return {
				...state,
				trips: action.payload,
				fetchError: '',
				fetchLoading: false
			};
		case 'FETCH_TRIPS_FAILURE':
			return {
				...state,
				trips: [],
				fetchError: action.payload,
				fetchLoading: false
			};

		default:
			return state;
	}
};

export default tripReducer;
