import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SiteHeader extends Component {
	render() {
		return (
			<footer className="container-fluid">
				<div className="d-flex flex-column flex-md-row text-center text-md-left justify-content-md-between">
					<div><Link className="text-xs-center" to="/">WithinPH</Link> &copy; {new Date().getFullYear()}</div>
					<div>
					<ul className="list-inline d-flex flex-md-row flex-column justify-content-center">
							<li className="list-inline-item">
								<Link className="text-xs-center" to="/about">
									About
								</Link>
							</li>
							<li className="list-inline-item">
								<Link className="text-xs-center" to="/help">
									Help Center
								</Link>
							</li>
							<li className="list-inline-item">
								<Link className="text-xs-center" to="/terms">
									Terms of Use
								</Link>
							</li>
							<li className="list-inline-item">
								<Link className="text-xs-center" to="/policy">
									Policy
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		);
	}
}

export default SiteHeader;
