import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { convertWeatherIcons, createCityOverviews } from '../utils/helpers';
import config from '../utils/config';

import ProgressBar from './ProgressBar';
import DefaultImage from '../assets/images/item.png';

const CardCity = ({ city }) => {

	const [ image, setImage ] = useState(config.baseIMGURL + city.image || DefaultImage);
	const imageTitle =
		city.type + ' of ' + city.name + ' in ' + city.province_name + ' Philippines - WithinPH';

	return (
		<Link
			to={`${city.province_slug}/${city.slug}`}
			className="CityCard col-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-stretch"
		>
			<div className="card card-wrapper card-std">
				<div className="card-body card-item card-item-img">
					<img className="card-img" src={image} alt={imageTitle} onError={() => setImage(DefaultImage)} />
					<div className="card-img-overlay">
						<h5 className="card-title">
							{city.name}
							<span>{city.province_name}</span>
						</h5>
						<div className="card-text d-flex">
							<div className="card-text-otherinfo">
								{city.region_shortname ? city.region_shortname : city.region}
								{city.image_credit &&
								city.image_credit.trim().length > 0 && (
									<small>
										<i className="fas fa-camera mr-1" /> {city.image_credit}
									</small>
								)}
							</div>
							<div className="card-text-weather ml-auto">
								{city.weather_icon &&
								city.weather_icon.trim().length > 0 && (
									<i className={`fas ${convertWeatherIcons(city.weather_icon)}`} />
								)}
								<span>{Math.floor(city.temperature)}&#730;</span>
							</div>
						</div>
					</div>
				</div>

				{/* Ratings */}
				<div className="card-body card-item card-item-txt">
					<div className="card-body-content w-100">
						<div className="row pb-1">
							<div className="col-12">
								<p className="card-text">{createCityOverviews(city).substring(0, 100) + '...'}</p>
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-6">
								<strong>Score</strong>
							</div>
							<div className="col-6">
								<ProgressBar score={city.score} />
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-6">
								<strong>Attractions</strong>
							</div>
							<div className="col-6">
								<ProgressBar score={city.attractions} />
							</div>
						</div>

						<div className="row mt-2">
							<div className="col-6">
								<strong>Fun</strong>
							</div>
							<div className="col-6">
								<ProgressBar score={city.fun} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default CardCity;
