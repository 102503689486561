import axios from 'axios';
import config from '../../utils/config';

export const fetchHelpRequest = () => {
	return {
		type: 'FETCH_HELP_REQUEST'
	};
};

export const fetchHelpSuccess = (data) => {
	return {
		type: 'FETCH_HELP_SUCCESS',
		payload: data
	};
};

export const fetchHelpFailure = (data) => {
	return {
		type: 'FETCH_HELP_FAILURE',
		payload: data
	};
};

/*
** Fetch Help Content
*/
export const fetchHelp = () => {
	const url = config.baseAPIURL + 'helps';
	const hdr = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
        dispatch(fetchHelpRequest());
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				dispatch(fetchHelpSuccess(response.data));
			})
			.catch((error) => {
                const emsg = error.message;
                dispatch(fetchHelpFailure(emsg));
            });
	};
};
