import { useState, useEffect } from 'react';
import { validatesFormFields } from '../helpers/';

export const useForm = (initvalues, callback, allFieldsRequired = false) => {
	const [ values, setValues ] = useState(initvalues);
	const [ submit, setSubmit ] = useState(false);
	const [ errMsg, setErrors ] = useState('');

	useEffect(
		() => {
			// send callback if no errors found
			if (errMsg.length === 0 && submit) {
				callback();
			}

			setSubmit(false);
		},
		[ errMsg, submit ]
	);

	const handleSubmit = (event) => {
		if (event) event.preventDefault();
		
		// validate form fields before submitting
		setErrors(validatesFormFields(values, allFieldsRequired));
		setSubmit(true);
	};

	const handleChange = (event) => {
		event.persist();
		setValues((values) => ({ ...values, [event.target.name]: event.target.value }));
	};

	return {
		handleChange,
		handleSubmit,
		values,
		errMsg
	};
};
