import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProfile } from '../redux/actions';

import ProfileTripList from '../containers/ProfileTripList';
import ProfileMap from '../containers/ProfileMap';
import ProfileHeader from '../containers/ProfileHeader';

import MetaTags from '../components/MetaTags';
import Message from '../components/messages/DisplayMessage';
import TripModal from '../components/modals/TripModal';
import Loading from '../components/loading/BouncingDots';

const Profile = ({ match }) => {
	const dispatch = useDispatch();
	const urlParam = useParams();
	const history = useHistory();

	const username = urlParam.username;

	const loading = useSelector((state) => state.profile.loading);
	const error = useSelector((state) => state.profile.error);
	const profile = useSelector((state) => state.profile.profiles[username]);
	const user  = useSelector((state) => state.auth.user);

	useEffect(
		() => {
			if (username) {
				if (profile === undefined) {
					dispatch(fetchProfile(username));
				}
			} else {
				history.push('/');
			}
		},
		[ username ]
	);

	return (
		<div>
			<MetaTags data={profile} type="user" follow={true} />
			{loading ? (
				<Loading text="loading profile" />
			) : error ? (
				<Message message={error} buttons={true} />
			) : (
				profile && (
					<React.Fragment>
						<ProfileHeader profile={profile} />

						<section className="middle-user-main Profile">
							<div className="row">
								<div className="col-12 col-lg-4">
									<div className="middle-user-main-side">
										<ProfileMap />
									</div>
								</div>
								<div className="col-12 col-lg-8">
									<div className="middle-user-main-feed">
										<div className="content-title">
											<div className="row no-gutters">
												<div className="col-12 col-sm-6">
													<h6>Recent Trips</h6>
												</div>
												<div className="col-12 col-sm-6 d-flex justify-content-center d-sm-inline">
													{user && user.username === username ? (
														<button
															className="btn btn-sm btn-gray d-block d-sm-inline"
															data-toggle="modal"
															data-target="#TripModal"
														>
															<i className="fas fa-hiking" /> Add a Trip
														</button>
													) : (
														''
													)}
												</div>
											</div>
										</div>
										<ProfileTripList user={user} username={username} />
									</div>
								</div>
							</div>
						</section>
						{user && user.username === username ? <TripModal username={username} /> : ''}
					</React.Fragment>
				)
			)}
		</div>
	);
};

export default Profile;
