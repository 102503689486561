/* 
** Used by UseForm Custom Hooks
** to validate form fields
**
** Parameter: Object Input Fields and Boolean value to indicate whether all fields are required or not
** Return: String, Error
*/

import { validatesURLStrings } from './validatesURLStrings';

export function validatesFormFields(values, allFieldsRequired = false) {
	let errors = '';

	// website
	if (values.website) {
		if (!validatesURLStrings(values.website)) {
			errors = 'Website is invalid.';
		}
	}

	// lastname
	if (values.lastname) {
		if (/<\/?[a-z][\s\S]*>/.test(values.lastname)) {
			errors = 'Last Name is invalid.';
		}
	}

	// score
	if (values.score !== undefined) {
		if (values.score === 0) {
			errors = 'Overall Score is required.';
		}
	}

	// firstname
	if (values.firstname) {
		if (/<\/?[a-z][\s\S]*>/.test(values.firstname)) {
			errors = 'First Name is invalid.';
		}
	}

	// password
	if (values.password) {
		if (values.password.length < 8) {
			errors = 'Password must be at least 8 characters.';
		}
	}

	if (values.confirm) {
		if (values.confirm !== values.password) {
			errors = 'Passwords do not match.';
		}
	}

	// email address
	if (values.email) {
		if (!/\S+@\S+\.\S+/.test(values.email) || /<\/?[a-z][\s\S]*>/.test(values.email)) {
			errors = 'Email Address is invalid.';
		}
	}

	// username
	if (values.username) {
		if (values.username.search(/^[a-zA-Z0-9-_]+$/) === -1) {
			errors = 'Username must be alphanumeric.';
		} else if (/<\/?[a-z][\s\S]*>/.test(values.username)) {
			errors = 'Username is invalid.';
		}
	}

	// all fields are required
	if (allFieldsRequired) {
		Object.keys(values).forEach((key, index) => {
			if (!values[key]) {
				errors = 'All fields are required.';
			}
		});
	}

	return errors;
}
