/* 
** Used by Validates Form Fields
** to validate string url
**
** Parameter: String URL
** Return: Boolean
*/
export function validatesURLStrings(url) {
	const badSubStrs = [
		'porn',
		'fuck',
		'pornhub.com',
		'redtube.com',
		'xvideos.com',
		'xhamster.com',
		'xnxx.com',
		'chaturbate.com',
		'tinyurl.com',
		'bit.ly',
		'goo.gl',
		'shorturl.at',
		'rb.gy',
		'ow.ly',
		'buff.ly',
		'adf.ly',
		'is.gd',
		'su.pr',
		'mcaf.ee',
		'bit.do'
	];

	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
		'i'
	);

	return !!urlPattern.test(url) && !new RegExp(badSubStrs.join('|')).test(url.toLowerCase());
}
