import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../utils/config';

export const fetchReviewsRequest = () => {
	return {
		type: 'FETCH_REVIEWS_REQUEST'
	};
};

export const fetchReviewsFailure = (error) => {
	return {
		type: 'FETCH_REVIEWS_FAILURE',
		payload: error
	};
};

export const fetchReviewsSuccess = (data) => {
	return {
		type: 'FETCH_REVIEWS_SUCCESS',
		payload: data
	};
};

export const actionReviewRequest = () => {
	return {
		type: 'ACTION_REVIEW_REQUEST'
	};
};

export const createReviewSuccess = () => {
	return {
		type: 'CREATE_REVIEW_SUCCESS'
	};
};

export const updateReviewSuccess = () => {
	return {
		type: 'UPDATE_REVIEW_SUCCESS'
	};
};

export const deleteReviewSuccess = () => {
	return {
		type: 'DELETE_REVIEW_SUCCESS'
	};
};

export const actionReviewFailure = (error) => {
	return {
		type: 'ACTION_REVIEW_FAILURE',
		payload: error
	};
};

/* 
** Fetch Reviews
** of specific city
*/
export const fetchReviews = (param) => {
	const url = config.baseAPIURL + 'reviews/' + param;
	const hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchReviewsRequest());

		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				dispatch(fetchReviewsSuccess(data));
			})
			.catch((error) => {
				const emsg = error.message || 'Failed to fetch all reviews for this city. Please refresh.';
				dispatch(fetchReviewsFailure(emsg));
			});
	};
};

/* 
** Create Review
*/
export const createReview = (param, history) => {
	const userID = JSON.parse(localStorage.withinPHToken).id;
	const tokens = JSON.parse(localStorage.withinPHToken).data;

	const url = config.baseAPIURL + 'reviews/create';
	const hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`,
		'Content-Type': 'application/json'
	};
	const prm = { ...param, user_id: userID };

	return (dispatch) => {
		if (!userID || !tokens) {
			dispatch(actionReviewFailure('Authentication Failed!'));
		} else {
			dispatch(actionReviewRequest());
		}

		axios
			.post(url, prm, { headers: hdr })
			.then((response) => {
				toast.success('Your review has been added successfully!');
				dispatch(createReviewSuccess(response.data));
				history.push(`/${param.province_slug}/${param.city_slug}`);
			})
			.catch((error) => {
				const emsg = error.message || 'Failed to add new review. Please try again.';
				dispatch(actionReviewFailure(emsg));
			});
	};
};

/* 
** Delete Review
*/
export const deleteReview = (param) => {
	const userID = JSON.parse(localStorage.withinPHToken).id;
	const tokens = JSON.parse(localStorage.withinPHToken).data;

	const url = config.baseAPIURL + 'reviews/delete';
	const hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`,
		'Content-Type': 'application/json'
	};
	const prm = { ...param, user_id: userID };

	return (dispatch) => {
		if (!userID || !tokens) {
			dispatch(actionReviewFailure('Authentication Failed!'));
		} else {
			dispatch(actionReviewRequest());
		}

		axios
			.post(url, prm, { headers: hdr })
			.then((response) => {
				toast.success('Your review has been deleted successfully!');
				dispatch(deleteReviewSuccess(response.data));
				dispatch(fetchReviews(prm.city_id));
			})
			.catch((error) => {
				const emsg = error.message || 'Failed to delete the review. Please try again.';
				toast.error('Failed to delete your review. Please try again.');
				dispatch(actionReviewFailure(emsg));
			});
	};
};
