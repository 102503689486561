/* 
** Used convert string to Sentence Case
**
** Parameter: String
** Return: String
*/

export function formatStrSentenceCase(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
