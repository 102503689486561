import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { useForm } from '../../utils/hooks';
import { userSignUp } from '../../redux/actions';

import Loading from '../loading/BouncingDots';

const AuthSignUp = ({ ...props }) => {
	const dispatch = useDispatch();

	const hasErrors = useSelector((state) => state.auth.error);
	const isLoading = useSelector((state) => state.auth.isLoading);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const signup = () => {
		setLoading(true);
		dispatch(userSignUp(values));
	};

	const { values, errMsg, handleChange, handleSubmit } = useForm(
		{ username: '', password: '', email: '' },
		signup,
		true
	);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(isLoading);
				setError(hasErrors);
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
			}
		},
		[ errMsg, hasErrors, isLoading ]
	);

	return (
		<div>
			{isAuthenticated && <Redirect to="/" />}

			{error && (
				<div className="alert alert-danger" role="alert">
					{error}
				</div>
			)}

			<form className="auth-form" onSubmit={handleSubmit}>
				<div className="form-group">
					<label htmlFor="username" className="">
						Username
					</label>
					<input
						type="text"
						id="username"
						name="username"
						className="form-control"
						onChange={handleChange}
						value={values.username}
						required
					/>
				</div>

				<div className="form-group">
					<label htmlFor="email" className="">
						Email
					</label>
					<input
						type="email"
						id="email"
						name="email"
						className="form-control"
						onChange={handleChange}
						value={values.email}
						required
					/>
				</div>

				<div className="form-group">
					<label htmlFor="password" className="">
						Password (minimum 8 char)
					</label>
					<input
						type="password"
						id="password"
						name="password"
						className="form-control"
						onChange={handleChange}
						value={values.password}
						required
					/>
				</div>

				<div className="footnote">
					By clicking Join Now, you agree to the WithinPH's{' '}
					<Link to="/terms" target="_blank">
						User Agreement
					</Link>&nbsp;and&nbsp;
					<Link to="/policy" target="_blank">
						Policy
					</Link>.
					<br />
					<br />
				</div>

				{loading ? (
					<button className="btn btn-gray btn-block" type="submit" disabled>
						<Loading text="this may take a while" />
					</button>
				) : (
					<button className="btn btn-gray btn-block" type="submit">
						Join Now
					</button>
				)}

				<div className="footnote">
					Already a member?&nbsp;
					{props.isPage ? (
						<Link to="/auth/signin">Login</Link>
					) : (
						<Link to="/auth/signin" data-toggle="modal" data-target="#signInModal" data-dismiss="modal">
							Login
						</Link>
					)}
				</div>
			</form>
		</div>
	);
};

export default AuthSignUp;
