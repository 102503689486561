import axios from 'axios';
import config from '../../utils/config';

export const fetchProfileRequest = () => {
	return {
		type: 'FETCH_PROFILE_REQUEST'
	};
};

export const fetchProfileSuccess = (data) => {
	return {
		type: 'FETCH_PROFILE_SUCCESS',
		payload: data
	};
};

export const fetchProfileFailure = (data) => {
	return {
		type: 'FETCH_PROFILE_FAILURE',
		payload: data
	};
};

/*
** Fetch Profile
*/
export const fetchProfile = (username) => {
	const url = config.baseAPIURL + 'profile/' + username;
	const hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchProfileRequest());

		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				response.data.username = username;
				dispatch(fetchProfileSuccess(response.data));
			})
			.catch((error) => {
				const emsg = error.message || 'User Could Not Be Found';
				dispatch(fetchProfileFailure(emsg));
			});
	};
};
