import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, useParams, useLocation } from 'react-router-dom';

import { useForm } from '../../utils/hooks';
import { SearchQueryToArrays } from '../../utils/helpers';
import { passwordResets, resetActions } from '../../redux/actions';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

const AuthResets = ({ ...props }) => {
	const dispatch = useDispatch();
	const urlQuery = useLocation();
	const urlParam = useParams();

	const hasErrors = useSelector((state) => state.password.resetsError);
	const isLoading = useSelector((state) => state.password.resetsLoading);
	const hasMesage = useSelector((state) => state.password.resetsMessage);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const resets = () => {
		setLoading(true);
		dispatch(passwordResets(values));
	};

	const { values, errMsg, handleChange, handleSubmit } = useForm(
		{ password: '', confirms: '', token: urlParam.token, email: SearchQueryToArrays(urlQuery.search).email },
		resets,
		true
	);
	const [ loading, setLoading ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ error, setError ] = useState(null);

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(isLoading);
				setError(hasErrors);
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
			}
		},
		[ errMsg, hasErrors, isLoading ]
	);

	useEffect(
		() => {
			if (hasMesage) {
				setMessage(hasMesage);
				setLoading(false);
				dispatch(resetActions());
			}
		},
		[ hasMesage ]
	);

	return (
		<div>
			{isAuthenticated && <Redirect to="/" />}

			{error && <Message type="alert-danger" message={error} />}

			{message !== null ? (
				<h5>{message}</h5>
			) : (
				<form className="auth-form" onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="password" className="">
							New Password
						</label>
						<input
							type="password"
							id="password"
							name="password"
							className="form-control"
							onChange={handleChange}
							value={values.password}
							required
						/>
					</div>
					<div className="form-group">
						<label htmlFor="confirms" className="">
							Confirm New Password
						</label>
						<input
							type="password"
							id="confirms"
							name="confirms"
							className="form-control"
							onChange={handleChange}
							value={values.confirms}
							required
						/>
					</div>
					{loading ? (
						<button className="btn btn-gray btn-block" type="submit" disabled>
							<Loading />
						</button>
					) : (
						<button className="btn btn-gray btn-block" type="submit">
							Update Password
						</button>
					)}
					<div className="footnote">
						Back to <Link to="/auth/signin">Sign In</Link>
					</div>
				</form>
			)}
		</div>
	);
};

export default AuthResets;
