import React from 'react';

import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

const CityPlace = ({ place, handleVotes, handleModal, auth }) => {
	/*
	** Trigger when Place Up or Down Button
	** are clicked
	*/
	const handleVote = (e) => {
		const vote = e.target.value;
		handleVotes(place.id, vote);
	};

	/*
	** Trigger when Place Name is clicked
	*/
	const handleClick = () => {
		let details = place;
		details.subtext = renderPlaceAddr;
		handleModal(details);
	};

	const vote = place.votes && place.votes.length > 0 ? Boolean(parseInt(place.votes[0].vote)) : null;

	const renderPlaceAddr = place.address ? (
		<a href={place.address} rel="noopener noreferrer" target="_blank">
			<i className="fas fa-map-marker-alt" /> See on Google Map
		</a>
	) : null;

	return (
		<React.Fragment>
			<div className="media">
				<img
					className="item-middle-list-img"
					src={place.image ? config.baseIMGURL + place.image : DefaultImage}
					alt={place.name}
					onClick={handleClick}
				/>

				<div className="media-body item-middle-list-txt">
					<button className="btn btn-borderless btn-sm" onClick={handleClick}>
						{place.name}
					</button>
					<span className="list-group-taxonomy">{renderPlaceAddr}</span>
					<span className="list-group-descript">
						{place.description ? place.description : 'No Information Found'}
					</span>
				</div>

				<div className="content-lists-vote-btn">
					<div className="list-vote-love">
						{auth ? (
							<button
								value="1"
								data-type={place.type}
								className={vote !== null && vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
					<div className="list-vote-text">{place.total_votes}</div>
					<div className="list-vote-hate">
						{auth ? (
							<button
								value="0"
								data-type={place.type}
								className={vote !== null && !vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && !vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CityPlace;
