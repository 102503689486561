import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';

import Message from '../messages/AlertMessage';
import ConfirmDelete from '../modals/ConfirmDelete';

const SettingsDelete = ({ user, handleUpdate }) => {
	const hasErrors = useSelector((state) => state.auth.deleteError);

	const handleDelete = () => {
		$('#DeleteModal').modal('hide');
		handleUpdate('accounts-delete', null);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		$('#DeleteModal').modal('show');
	};

	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setError(hasErrors);
			}
		},
		[ hasErrors ]
	);

	return (
		<React.Fragment>
			<form id="accounts-delete" className="settings-form" onSubmit={handleSubmit}>
				<h5>Permanently Delete Your Account</h5>
				{error && <Message type="alert-danger" message={error} />}
				<p>
					When you press the button below, your photos, reviews, and all other data will be removed
					permanently and will not be recoverable. If you decide to create a new account in the future, you
					cannot sign up with the same username and email. If you understand and agree, and would still like
					to delete your account, click below.
				</p>
				<div className="d-flex justify-content-start">
					<button className="btn btn-sm btn-danger" type="submit">
						Delete My Account
					</button>
				</div>
			</form>

			<ConfirmDelete id="DeleteModal" type="account" handleDelete={handleDelete} />
		</React.Fragment>
	);
};

export default SettingsDelete;
