import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import config from '../utils/config';
import DefaultAvatar from '../assets/images/user.png';

import Rating from '../components/StarRatingDisplay';

const CityReview = ({ review, user, handleDelete }) => {
	const handleClick = (e) => {
		handleDelete(e.target.id);
	};

	return (
		<React.Fragment>
			<div className="list-group-item flex-column">
				<div className="media">
					<img
						src={review.reviewer.image ? config.baseIMGURL + review.reviewer.image : DefaultAvatar}
						alt={`@${review.reviewer.username}`}
					/>
					<p className="media-body">
						<Link to={`/user/${review.reviewer.username}`} className="mr-1">
							{review.reviewer.name}
						</Link>
						<span className="comment-score">
							rated this&nbsp;<Rating name={`score-${review.id}`} rating={review.score} />
						</span>
						{user ? user.username === review.reviewer.username ? (
							<button
								id={review.id}
								type="submit"
								className="btn btn-xs btn-borderless btn-comment btn-comment-trash"
								data-reviewid={review.id}
								onClick={handleClick}
							>
								delete
							</button>
						) : (
							<button
								className="btn btn-xs btn-borderless btn-comment btn-comment-more collapsed"
								data-toggle="collapse"
								href={`#MoreReviews${review.id}ID`}
								aria-expanded="false"
								aria-controls="moreReviews"
							>
								more
							</button>
						) : (
							<button
								className="btn btn-xs btn-borderless btn-comment btn-comment-more collapsed"
								data-toggle="collapse"
								href={`#MoreReviews${review.id}ID`}
								aria-expanded="false"
								aria-controls="moreReviews"
							>
								more
							</button>
						)}

						<span>{moment(review.updated_at).calendar(null, { sameElse: 'DD MMMM, YYYY' })}</span>
					</p>
				</div>
				{review.review && <p>{review.review}</p>}
				<div
					className={user ? user.username === review.reviewer.username ? '' : 'collapse' : 'collapse'}
					id={`MoreReviews${review.id}ID`}
				>
					<div className="content-lists-ratings d-flex flex-wrap m-0">
						<small>
							Fun: <Rating name={`fun-${review.id}`} rating={review.fun} />
						</small>

						<small>
							Attractions: <Rating name={`attractions-${review.id}`} rating={review.attractions} />
						</small>

						<small>
							Safety: <Rating name={`safety-${review.id}`} rating={review.safety} />
						</small>

						<small>
							Serenity: <Rating name={`serenity-${review.id}`} rating={review.serenity} />
						</small>

						<small>
							Nightlife: <Rating name={`nightlife-${review.id}`} rating={review.nightlife} />
						</small>

						<small>
							Accessibility: <Rating name={`accessibility-${review.id}`} rating={review.accessibility} />
						</small>

						<small>
							Accommodations:{' '}
							<Rating name={`accommodations-${review.id}`} rating={review.accommodations} />
						</small>

						<small>
							Activities: <Rating name={`activities-${review.id}`} rating={review.activities} />
						</small>

						<small>
							Festivals: <Rating name={`festivals-${review.id}`} rating={review.festivals} />
						</small>

						<small>
							Eateries: <Rating name={`eateries-${review.id}`} rating={review.eateries} />
						</small>

						<small>
							Shops: <Rating name={`shops-${review.id}`} rating={review.shops} />
						</small>

						<small>
							Friendliness: <Rating name={`friendliness-${review.id}`} rating={review.friendliness} />
						</small>

						<small>
							Helpfulness: <Rating name={`helpfulness-${review.id}`} rating={review.helpfulness} />
						</small>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CityReview;
