import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HelpLast extends Component {
	render() {
		return (
			<div className="accordion" id="FAQ-100">
				<div className="card">
					<div className="card-header" id="FAQHeading-100">
						<h5>
							<button
								className="btn btn-link"
								type="button"
								data-toggle="collapse"
								data-target="#collapse100"
								aria-expanded="false"
								aria-controls="collapse100"
							>
								Privacy Policy and Terms
							</button>
						</h5>
					</div>

					<div id="collapse100" className="collapse" aria-labelledby="FAQHeading-100" data-parent="#FAQ-100">
						<div className="card-body">
							<b>Answer:</b>
							<div>
								In a nutshell, we accept no liability or responsibility for any loss, injury or
								inconvenience to any person or organisation as a consequence of any reliance upon the
								information contained in WithinPH. WithinPH encourages every user to double-check any
								information found on WithinPH before travelling to any cities or municipalities listed
								on WithinPH. Read more here: <Link to="/terms">Terms</Link>,{' '}
								<Link to="/policy">Privacy Policy</Link>.
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HelpLast;
