import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageSidebar extends Component {
	render() {
		return (
			<div className="middle-page-main-sidebar">
				<div className="sidebar-card">
					{/* Social Media */}
					<div className="btn-group my-3 d-flex" role="group">
						<a
							href="https://www.facebook.com/gowithinph"
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-gray"
						>
							<i className="fab fa-facebook-square mr-1" />
							<span className="d-none d-sm-inline">Facebook</span>
						</a>
						<a
							href="https://www.twitter.com/gowithinph"
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-gray"
						>
							<i className="fab fa-twitter mr-1" />
							<span className="d-none d-sm-inline">Twitter</span>
						</a>
						<a
							href="https://www.instagram.com/gowithinph"
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-gray"
						>
							<i className="fab fa-instagram  mr-1" />
							<span className="d-none d-sm-inline">Instagram</span>
						</a>
					</div>

					{/* Info Card */}
					<div className="card">
						<img className="card-img-top" src="/img/info.png" alt="Join WithinPH.com" />
						<div className="card-body">
							<p className="card-text">
								Be a member of WithinPH and start sharing your trips, rating your favorite cities, and
								other features exclusive for members only.
							</p>
							<Link to="/auth/signup" className="btn btn-sm btn-info">
								Join Now <i className="fas fa-arrow-right ml-1" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PageSidebar;
