import React, { Component } from 'react';
import { getScoreDisplays } from '../utils/helpers';

class ProgressBar extends Component {
	render() {
		const { score, label } = this.props;
		const rate = (score || 0) / 5 * 100;
		const text = label === 'score' ? score + '/5' : getScoreDisplays(rate).text;
		const clss = getScoreDisplays(rate).clss;

		return (
			<div className="progress">
				{label && <span>{text}</span>}
				<div
					className={`progress-bar ${clss}`}
					role="progressbar"
					style={{ width: rate + '%' }}
					aria-valuenow={rate}
					aria-valuemin="0"
					aria-valuemax="100"
				/>
			</div>
		);
	}
}

export default ProgressBar;
