/*
** Convert OpenWeatherMap Icon Code 
** to a FontAwesome Icon
**  
** Parameter: String, OpenWeatherMap Icon
** Return: String, FontAwesome Icon
*/
export function convertWeatherIcons(currentIcon) {
	let newIcon;

	switch (currentIcon) {
		case '01d':
			newIcon = 'fa-sun';
			break;
		case '01n':
			newIcon = 'fa-moon';
			break;
		case '02d':
			newIcon = 'fa-cloud-sun';
			break;
		case '02n':
			newIcon = 'fa-cloud-moon';
			break;
		case '03d':
		case '03n':
			newIcon = 'fa-cloud';
			break;
		case '04d':
		case '04n':
			newIcon = 'fa-cloud';
			break;
		case '09d':
		case '09n':
			newIcon = 'fa-cloud-rain';
			break;
		case '10d':
			newIcon = 'fa-cloud-sun-rain';
			break;
		case '10n':
			newIcon = 'fa-cloud-moon-rain';
			break;
		case '11d':
		case '11n':
			newIcon = 'fa-cloud-showers-heavy';
			break;
		case '13d':
		case '13n':
			newIcon = 'fa-snowflake';
			break;
		case '50d':
		case '50n':
			newIcon = 'fa-wind';
			break;
		default:
			newIcon = '';
	}

	return newIcon;
}
