import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCityDetails } from '../redux/actions/';
import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

import MetaTags from '../components/MetaTags';
import Loading from '../components/loading/BouncingDots';
import ReviewForm from '../components/forms/ReviewForm';

const Reviews = ({ ...props }) => {
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const city = useSelector((state) => state.cityDetail.city);

	const [ activeCity ] = useState({
		province: props.match.params.province || '',
		city: props.match.params.city || ''
	});

	useEffect(
		() => {
			let apiParam = `${activeCity.province}/${activeCity.city}`;

			// fetch city details on page load
			if (city.province_slug !== activeCity.province || city.slug !== activeCity.city) {
				dispatch(fetchCityDetails(apiParam));
			}
		},
		[ activeCity ]
	);

	return (
		<div className="Review">
			{!isAuthenticated && <Redirect to="/auth/signin" />}
			<MetaTags data={city} type="review" follow={false} />
			{city.id ? (
				<React.Fragment>
					<span className="review-title-link">
						<Link to={`/${city.province_slug}/${city.slug}`}>
							Back to {city.type} of {city.name} Page
						</Link>
						<i className="fas fa-chevron-right" />
					</span>
					<div className="review-title clearfix">
						<img
							className="img-fluid float-left"
							src={city.image ? config.baseIMGURL + city.image : DefaultImage}
							alt={`Review ${city.name} ${city.province_name} Philippines`}
						/>
						<h2>
							<Link to={`/${city.province_slug}/${city.slug}`}>
								{city.type} of {city.name}
							</Link>
						</h2>
						<span>Province of {city.province_name}</span>
					</div>

					<ReviewForm city={city} />
				</React.Fragment>
			) : (
				<Loading text="loading review form" />
			)}
		</div>
	);
};

export default Reviews;
