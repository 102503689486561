import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from '../../utils/hooks';

import Loading from '../loading/BouncingDots';
import Message from '../messages/AlertMessage';

const SettingsPassword = ({ user, handleUpdate }) => {
	const isLoading = useSelector((state) => state.auth.passwordLoading);
	const hasErrors = useSelector((state) => state.auth.passwordError);

	const handleSend = () => {
		setLoading(true);
		handleUpdate('password', values);
	};

	const { values, errMsg, handleChange, handleSubmit } = useForm({ password: '', confirm: '' }, handleSend, true);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(false);
				setError(hasErrors);
			}

			if (errMsg) {
				setLoading(false);
				setError(errMsg);
			}

			setLoading(isLoading);
		},
		[ errMsg, hasErrors, isLoading ]
	);

	return (
		<div className="card-body">
			{error && <Message type="alert-danger" message={error} />}
			To update your password, enter your new password below, confirm, and click Change Password. Your password
			must be at least 8 characters long. It's a good idea to combine uppercase letters, lowercase letters,
			numbers, and symbols to create a strong password.
			<form id="password" className="settings-form" onSubmit={handleSubmit}>
				<div className="form-row">
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label htmlFor="password">Password</label>
							<input
								type="password"
								className="form-control"
								id="password"
								name="password"
								value={values.password}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="form-group">
							<label htmlFor="confirm">Confirm Password</label>
							<input
								type="password"
								className="form-control"
								id="confirm"
								name="confirm"
								value={values.confirm}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-end mt-3">
					{loading ? (
						<button className="btn btn-sm btn-gray" type="submit" disabled>
							<Loading />
						</button>
					) : (
						<button className="btn btn-sm btn-gray" type="submit">
							Change Password
						</button>
					)}
				</div>
			</form>
		</div>
	);
};

export default SettingsPassword;
