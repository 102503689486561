import React, { Component } from 'react';
import axios from 'axios';

import config from '../utils/config';
import Loader from '../components/loading/BouncingDots';

import MetaTag from '../components/MetaTags';

class Verify extends Component {
	componentWillMount() {
		const { match } = this.props;

		/*
        ** Check if Verification Token is available
        ** redirect to homepage if no verification token
        */
		if (match.params.token) {
			this.verifyEmail(match.params.token);
		} else {
			this.props.history.push('/');
		}
	}

	verifyEmail(token) {
		// define api url
		const url = config.baseAPIURL + 'email/verify/' + token;

		/*
		** verify email verification token 
		** using axios
		*/
		axios
			.get(url)
			.then((response) => {
				// verification successful
				// redirect back to homepage with success message
				this.props.history.push({
					pathname: '/',
					state: { alert: { message: 'Awesome! Your email has been verified.', type: 'alert-success' } }
				});
			})
			.catch((error) => {
				// verification failed
				let errMsg = 'Error processing your verification. Please try again or contact our support.';
				let errTyp = 'alert-danger';

				if (error.message.includes('422')) {
					// user is verified already
					errMsg = "Hmmm... There seems to be a mistake cause you're verified already.";
					errTyp = 'alert-warning';
				}

				// redirect back to homepage with error message
				this.props.history.push({
					pathname: '/',
					state: { alert: { message: errMsg, type: errTyp } }
				});
			});
	}

	render() {
		return (
			<section className="middle-page-main">
				<MetaTag follow={false} />
				<Loader />
			</section>
		);
	}
}

export default Verify;
