import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import config from '../utils/config';
import { formatStrSentenceCase } from '../utils/helpers/';

const MetaTags = ({ data, type, follow }) => {
	const [ metaTitle, setMetaTitle ] = useState('WithinPH - Best Places to Visit Within the Philippines');
	const [ metaAbout, setMetaAbout ] = useState('Explore the best places to see in the Philippines. Based on fun, weather, attractions, nightlife, local friendliness, etc.');
	const [ metaLinks, setMetaLinks ] = useState(config.publicURL);
	const [ metaImage, setMetaImage ] = useState(`${config.publicURL}img/screens.png`);

	useEffect(
		() => {
			if (data && Object.keys(data).length) {
				switch (type) {
					case 'province':
						setMetaTitle(`${formatStrSentenceCase(data.name)} Province - WithinPH`);
						setMetaAbout(
							`Explore the best places to see in Province of ${data.name}, Philippines. Filter and sort cities based on fun, weather, attractions, local friendliness, etc.`
						);
						setMetaLinks(`${config.publicURL}${data.slug}`);
						break;
					case 'city':
						setMetaTitle(`${data.type} of ${data.name}, ${data.province_name} - WithinPH`);
						setMetaAbout(
							`Explore the best spots to visit in the ${data.type} of ${data.name}, ${data.province_name}. See reviews, recommended tourist attractions, and travel guides.`
						);
						setMetaLinks(`${config.publicURL}${data.slug}`);
						if(data.image){
							setMetaImage(`${config.baseIMGURL}${data.image}`);
						}
						break;
					case 'review':
						setMetaTitle(`Review ${data.type} of ${data.name}, ${data.province_name} - WithinPH`);
						setMetaAbout(
							`Leave a review on your experience in the ${data.type} of ${data.name}, ${data.province_name}.`
						);
						setMetaLinks(`${config.publicURL}${data.slug}`);
						setMetaImage(`${config.baseIMGURL}${data.image}`);
						break;
					case 'page':
						setMetaTitle(`${data.title} - WithinPH`);
						if (data.content) {
							setMetaAbout(`${data.content.substring(0, 200)}`);
						}
						setMetaLinks(`${config.publicURL}${data.slug}`);
						break;
					case 'user':
						setMetaTitle(`@${data.username} - WithinPH`);
						setMetaAbout(
							`See recent trips of @${data.username} within the archipelago of the Philippines. Explore the best places to see in the Philippines.`
						);
						setMetaLinks(`${config.publicURL}u/${data.username}`);
						break;
					default:
						setMetaTitle('WithinPH - Best Places to Visit Within the Philippines');
						setMetaAbout(
							'Explore the best places to see in the Philippines. Based on fun, weather, attractions, nightlife, local friendliness, etc.'
						);
				}
			}
		},
		[ type, data ]
	);

	return (
		<Helmet>
			<title>{metaTitle}</title>
			<meta name="description" content={metaAbout} />

			{follow ? (
				<meta name="robots" content="index, follow"     />
			) : (
				<meta name="robots" content="noindex, nofollow" />
			)}

			{/* Twitter */}
			<meta name="twitter:title"       content={metaTitle} />
			<meta name="twitter:card"        content={metaAbout} />
			<meta name="twitter:description" content={metaAbout} />
			<meta name="twitter:image"       content={metaImage} />

			{/* Open Graph */}
			<meta property="og:title"       content={metaTitle} />
			<meta property="og:description" content={metaAbout} />
			<meta property="og:url"         content={metaLinks} />
			<meta property="og:image"       content={metaImage} />
		</Helmet>
	);
};

export default MetaTags;
