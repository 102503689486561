import React from 'react';
import moment from 'moment';

import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

const CityEvent = ({ event, handleVotes, handleModal, auth }) => {
	/*
	** Trigger when Place Up or Down Button
	** are clicked
	*/
	const handleVote = (e) => {
		const vote = e.target.value;
		handleVotes(event.id, vote);
	};

	/*
	** Trigger when Place Name is clicked
	*/
	const handleClick = () => {
		let details = event;
		details.subtext = renderEventDate;

		handleModal(details);
	};

	const vote = event.votes && event.votes.length > 0 ? Boolean(parseInt(event.votes[0].vote)) : null;

	const renderEventDate = event.date_texts ? (
		<span>
			<i className="fas fa-calendar-day mr-1" /> {event.date_texts}
		</span>
	) : event.date_end ? (
		<span>
			<i className="fas fa-calendar-day mr-1" />
			{moment(event.date_start).format('MMM Do') + ' - ' + moment(event.date_end).format('MMM Do')}
		</span>
	) : (
		<span>
			<i className="fas fa-calendar-day mr-1" />
			{moment(event.date_start).format('MMM Do')}
		</span>
	);

	return (
		<React.Fragment>
			<div className="media">
				<img
					className="item-middle-list-img"
					src={event.image ? config.baseIMGURL + event.image : DefaultImage}
					alt={event.name}
					onClick={handleClick}
				/>

				<div className="media-body item-middle-list-txt">
					<button className="btn btn-borderless btn-sm" onClick={handleClick}>
						{event.name}
					</button>
					<span className="list-group-taxonomy">
						{renderEventDate}
						{event.can_change ? "(date may change)" : ""}
					</span>
					<span className="list-group-descript">
						{event.description ? event.description : 'No Information Found'}
					</span>
				</div>

				<div className="content-lists-vote-btn">
					<div className="list-vote-love">
						{auth ? (
							<button
								value="1"
								data-type={event.type}
								className={vote !== null && vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
					<div className="list-vote-text">{event.total_votes}</div>
					<div className="list-vote-hate">
						{auth ? (
							<button
								value="0"
								data-type={event.type}
								className={vote !== null && !vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleVote}
								disabled={vote !== null && !vote}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CityEvent;
