import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { SearchQueryToArrays, formatQueryParameters } from '../utils/helpers';

import Filter from '../components/Filter';
import FilterProvinces from '../components/FilterProvinces';
import FilterMonths from '../components/FilterMonths';

const EventsFilters = ({ ...props }) => {
	const urlPaths = useHistory();
	const urlQuery = useLocation();
	const urlParam = useParams();

	const [ filters, setFilters ] = useState({
		province: urlParam.province || '',
		months: SearchQueryToArrays(urlQuery.search).months || '',
		sortby: SearchQueryToArrays(urlQuery.search).sortby || ''
	});

	const renderSortby = [
		{
			title: 'Score',
			value: 'score'
		},
		{
			title: 'Date',
			value: 'date'
		},
		{
			title: 'Name',
			value: 'name'
		}
	];

	/*
	** update url
	** everytime filter is updated
	*/
	useEffect(
		() => {
			let urlParam = '';

			urlParam += `/events/${filters.province}`;
			urlParam += formatQueryParameters(filters);

			urlParam = urlParam.replace('&', '?');
			urlPaths.push(urlParam);
		},
		[ filters ]
	);

	const handleChange = (e) => {
		setFilters({ ...filters, [e.target.id]: e.target.value });
	};

	return (
		<section className="middle-card-filter">
			<div className="row no-gutters">
				<div className="col-12 col-md-5 col-lg-4 col-xl-3 card-filter-show">
					<div className="input-group input-group-sm">
						<label className="input-group-text d-none d-md-flex">Display Events On</label>
						<FilterMonths initial={filters.months} name="months" handleChange={handleChange} />
					</div>
				</div>

				<div className="col-12 col-md-4 col-lg-4 col-xl-3 card-filter-show">
					<div className="input-group input-group-sm">
						<label className="input-group-text pl-0 pl-md-2">From</label>
						<FilterProvinces handleChange={handleChange} initial={filters.province} />
					</div>
				</div>

				<div className="col-12 col-md-3 col-lg-4 col-xl-6 card-filter-sort">
					<div className="input-group input-group-sm">
						<label className="input-group-text pl-0 pl-md-2">
							Sort&nbsp;<span className="d-none d-md-block">By</span>
						</label>
						<Filter
							initial={filters.sortby}
							name="sortby"
							options={renderSortby}
							handleChange={handleChange}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default EventsFilters;
