/*
** Convert Search Query String 
** to Associative Array
**  
** Parameter: Search Query String
** Return: Associative Array
*/
export function SearchQueryToArrays(search = '') {
	let queryStr = search,
		queryArr = queryStr.replace('?', '').split('&'),
		queryVal = [];

	if (!queryStr) {
		return {};
	}

	for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
		var qArr = queryArr[q].split('=');
		queryVal[qArr[0]] = qArr[1];
	}

	return queryVal;
}
