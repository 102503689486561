import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCityDetails } from '../redux/actions/';

import CityBreads from '../containers/CityBreadcrumbs';
import CityHeader from '../containers/CityHeader';
import CityOverview from '../containers/CityOverview';
import CityReviews from '../containers/CityReviewList';
import CityPlaces from '../containers/CityPlaceList';
import CityEvents from '../containers/CityEventList';
import CityGuides from '../containers/CityGuideList';

import MetaTags from '../components/MetaTags';
import VerifyEmail from '../components/modals/VerifyEmail';
import Message from '../components/messages/DisplayMessage';
import Loading from '../components/loading/BouncingDots';

const City = ({ match }) => {
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const city = useSelector((state) => state.cityDetail.city);
	const user = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.cityDetail.loading);
	const hasErrors = useSelector((state) => state.cityDetail.error);

	const [ currentTab, setCurrentTab ] = useState('one');
	const [ activeCity, setActiveCity ] = useState({
		province: match.params.province || '',
		city: match.params.city || ''
	});

	useEffect(
		() => {
			let apiParam = `${activeCity.province}/${activeCity.city}`;

			if (city.province_slug !== activeCity.province || city.slug !== activeCity.city) {
				// fetch city details on page load
				dispatch(fetchCityDetails(apiParam));
				setActiveCity('one');
			}
		},
		[ activeCity ]
	);

	useEffect(
		() => {
			/*
			** check if URL was updated
			** then update city if changed
			*/
			if (match.params.province !== activeCity.province || match.params.city !== activeCity.city) {
				setCurrentTab('one');
				setActiveCity({
					province: match.params.province,
					city: match.params.city
				});
			}
		},
		[ match ]
	);

	const handleTabClick = (e) => {
		setCurrentTab(e.target.id || e.target.dataset.id);
	};

	return (
		<React.Fragment>
			{/* MetaTags */}
			<MetaTags data={city} type="city" follow={true} />

			{/* Loader */}
			{isLoading && <Loading text={`loading ${match.params.city}`} />}

			{/* Errors */}
			{hasErrors && (
				<Message
					message={`Sorry, we were not able to find any city named ${match.params.city.toUpperCase()} in the province of ${match.params.province.toUpperCase()}.`}
					buttons={true}
				/>
			)}

			{/* Detail */}
			{Object.keys(city).length > 0 && (
				<div className="CityDetails">
					<CityBreads city={city} />
					<CityHeader city={city} user={user} tabClick={handleTabClick} isAuthenticated={isAuthenticated} />

					<section className="middle-item-main">
						<div className="middle-item-main-nav">
							<ul className="nav nav-pills nav-fill" id="pills-tab" role="tablist">
								<li className="nav-item">
									<Link
										className="nav-link active"
										id="one"
										data-toggle="pill"
										to="#tab-one-content"
										role="tab"
										aria-controls="tab-one-content"
										onClick={handleTabClick}
									>
										<i data-id="one" onClick={handleTabClick} className="fas fa-clipboard-list d-block d-sm-none"></i>
										<span data-id="one" onClick={handleTabClick} className="d-none d-sm-block">Overview</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										id="two"
										data-toggle="pill"
										to="#tab-two-content"
										role="tab"
										aria-controls="tab-two-content"
										onClick={handleTabClick}
									>
										<i data-id="two" onClick={handleTabClick} className="fas fa-map-signs d-block d-sm-none"></i>
										<span data-id="two" onClick={handleTabClick} className="d-none d-sm-block d-md-none">Places</span>
										<span data-id="two" onClick={handleTabClick} className="d-none d-md-block">Attractions</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										id="tri"
										data-toggle="pill"
										to="#tab-tri-content"
										role="tab"
										aria-controls="tab-tri-content"
										onClick={handleTabClick}
									>	
										<i data-id="tri" onClick={handleTabClick} className="fas fa-calendar-alt d-block d-sm-none"></i>
										<span data-id="tri" onClick={handleTabClick} className="d-none d-sm-block">Festivals</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										id="for"
										data-toggle="pill"
										to="#tab-for-content"
										role="tab"
										aria-controls="tab-for-content"
										onClick={handleTabClick}
									>
										<i data-id="for" onClick={handleTabClick} className="fas fa-comment-alt d-block d-sm-none"></i>
										<span data-id="for" onClick={handleTabClick} className="d-none d-sm-block">Reviews</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										id="fiv"
										data-toggle="pill"
										to="#tab-fiv-content"
										role="tab"
										aria-controls="tab-fiv-content"
										onClick={handleTabClick}
									>
										<i data-id="fiv" onClick={handleTabClick} className="fas fa-rss-square d-block d-sm-none"></i>
										<span data-id="fiv" onClick={handleTabClick} className="d-none d-sm-block d-md-none">Blogs</span>
										<span data-id="fiv" onClick={handleTabClick} className="d-none d-md-block">Blogs &amp; Vlogs</span>
									</Link>
								</li>
							</ul>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="tab-content middle-item-main-content">
									<div
										className={currentTab === 'one' ? 'tab-pane show active' : 'tab-pane fade'}
										id="tab-one-content"
									>
										<CityOverview city={city} user={user} isAuthenticated={isAuthenticated} />
									</div>
									<div
										className={currentTab === 'two' ? 'tab-pane show active' : 'tab-pane fade'}
										id="tab-two-content"
									>
										<CityPlaces city={city} />
									</div>
									<div
										className={currentTab === 'tri' ? 'tab-pane show active' : 'tab-pane fade'}
										id="tab-tri-content"
									>
										<CityEvents city={city} />
									</div>
									<div
										className={currentTab === 'for' ? 'tab-pane show active' : 'tab-pane fade'}
										id="tab-for-content"
									>
										<CityReviews city={city} isAuthenticated={isAuthenticated} />
									</div>
									<div
										className={currentTab === 'fiv' ? 'tab-pane show active' : 'tab-pane fade'}
										id="tab-fiv-content"
									>
										<CityGuides city={city} />
									</div>
								</div>
							</div>
						</div>
					</section>
					{!user.is_verified && <VerifyEmail />}
				</div>
			)}
		</React.Fragment>
	);
};

export default City;
