import React, { Component } from 'react';

import config from '../utils/config';
import { formatStrSentenceCase } from '../utils/helpers/';

import Loading from '../components/loading/BouncingDots';
import Message from '../components/messages/DisplayMessage';

class PageContent extends Component {
	render() {
		const { details, type } = this.props;

		return (
			<div className="tab-content middle-page-main-content">
				<div className="content-title">
					<div>
						<h1>{details.pages && details.pages[type] !== undefined ? details.pages[type].title : formatStrSentenceCase(type)}</h1>
					</div>
				</div>
				<div className="content-texts">
					{details.loading ? (
						<Loading text="loading page" />
					) : details.error ? (
						<Message message={details.error} />
					) : (
						details.pages[type] && (
							<React.Fragment>
								{details.pages[type].image && (
									<img
										className="img-fluid"
										src={config.baseIMGURL + details.pages[type].image}
										alt={details.pages[type].title}
									/>
								)}
								<div dangerouslySetInnerHTML={{ __html: details.pages[type].content }} />
							</React.Fragment>
						)
					)}
				</div>
			</div>
		);
	}
}

export default PageContent;
