const initState = {
	loading: false,
    error:   '',
    pages:   {},
};

const pageReducer = (state = initState, action) => {
	switch (action.type) {
        case 'FETCH_PAGE_REQUEST':
            return {
                ...state,
                loading: true,
                error:     ''
            };

        case 'FETCH_PAGE_SUCCESS':
            const page = [];
            page[action.payload.type]  = action.payload;

			return {
                pages:   {...state.pages, ...page},
				error:     '',
				loading: false
            };
            
        case 'FETCH_PAGE_FAILURE':
            return {
                ...state,
				error:   action.payload,
				loading: false
            };

		default:
			return state;
	}
};

export default pageReducer;
