import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { createCityOverviews, attachUTMCodeOnLink } from '../utils/helpers';
import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

import Rating from '../components/StarRatingDisplay';

class CityHeader extends Component {
	constructor(props) {
		super(props);

		this.state = { activetab: 'one' };
		this.handleClickTab = this.handleClickTab.bind(this);
	}

	handleClickTab(e) {
		let tabID = e.target.id;

		e.preventDefault();
		this.props.tabClick(tabID);
	}

	render() {
		const { city } = this.props;

		const oldname_name = city.byname ? '(' + city.byname + ')' : '';

		return (
			<section className="middle-item-head">
				<div className="row no-gutters">
					<div className="col-lg-8 d-flex align-items-stretch order-2 order-lg-1 middle-item-head-bio">
						<div className="middle-item-head-txt">
							<div className="middle-item-head-title">
								{city.seal && (
									<img
										className="city-seal"
										src={city.seal}
										alt={`${city.type} of ${city.name} Seal`}
									/>
								)}
								<h1>
									{city.name} {oldname_name}
								</h1>
							</div>
							<p className="mb-2">
								<i className="fas fa-map-marker-alt mr-1" />
								<a
									href={`https://maps.google.com/?q=${city.latitude},${city.longitude}&ll=${city.latitude},${city.longitude}&z=15`}
									target="_blank"
									rel="noopener noreferrer"
									title={`${city.name}, ${city.province_name} via Google Map`}
								>
									{city.region_name} - {city.region_byname}, Province of {city.province_name}
								</a>
							</p>
							<p>
								<Rating
									name="score"
									rating={city.average_ratings.length ? city.average_ratings[0].score : 0}
								/>

								<span className="ml-1">
									{city.average_ratings.length && city.average_ratings[0].review_count > 0 ? (
										<span className="mr-1">
											{city.average_ratings[0].score}&nbsp;({city.average_ratings[0].review_count}{' '}
											reviews)
										</span>
									) : (
										'no reviews yet'
									)}
								</span>
							</p>

							<p className="mt-3 text-justify">{createCityOverviews(city)}</p>

							<ul className="list-inline">
								{city.website && (
									<li>
										<i className="fas fa-globe" />
										<a
											href={attachUTMCodeOnLink(city.website)}
											target="_blank"
											rel="nofollow noopener noreferrer"
											title={city.name + ' Official Website'}
										>
											Official Website
										</a>
									</li>
								)}
								{city.facebook && (
									<li>
										<i className="fab fa-facebook-square" />
										<a
											href={attachUTMCodeOnLink(city.facebook)}
											target="_blank"
											rel="noopener noreferrer"
											title={city.name + ' Official Facebook Page'}
										>
											Facebook Page
										</a>
									</li>
								)}
								{city.wikipedia && (
									<li>
										<i className="fab fa-wikipedia-w" />
										<a
											href={attachUTMCodeOnLink(city.wikipedia)}
											target="_blank"
											rel="noopener noreferrer"
											title={city.name + ' Wikipedia Page'}
										>
											Wikipedia
										</a>
									</li>
								)}
							</ul>
						</div>
					</div>

					<div className="col-lg-4 d-flex align-items-stretch order-1 order-lg-2 middle-item-head-img">
						<img
							className="img-fluid"
							src={city.image ? config.baseIMGURL + city.image : DefaultImage}
							alt={`${city.name} ${city.province_name} Philippines`}
						/>
						{city.image_credit &&
						city.image_credit.trim().length > 0 && (
							<small>
								<i className="fas fa-camera mr-1" /> {city.image_credit}
							</small>
						)}
					</div>
				</div>

				<div className="middle-item-head-btn">
					{this.props.isAuthenticated ? this.props.user.is_verified ? (
						<Link
							to={`/review/${city.province_slug}/${city.slug}`}
							className="btn btn-borderless stars"
							title="Review"
						/>
					) : (
						<button
							className="btn btn-borderless stars"
							data-toggle="modal"
							title="Review"
							data-target="#VerifyEmail"
						/>
					) : (
						<button
							className="btn btn-borderless stars"
							data-toggle="modal"
							title="Review"
							data-target="#signInModal"
						/>
					)}
				</div>
			</section>
		);
	}
}

export default CityHeader;
