import axios from 'axios';
import config from '../../utils/config';

export const actionForgotRequest = () => {
	return {
		type: 'ACTION_FORGOT_REQUEST'
	};
};

export const actionForgotSuccess = (data) => {
	return {
		type: 'ACTION_FORGOT_SUCCESS',
		payload: data
	};
};

export const actionForgotFailure = (data) => {
	return {
		type: 'ACTION_FORGOT_FAILURE',
		payload: data
	};
};

export const actionResetsRequest = () => {
	return {
		type: 'ACTION_RESETS_REQUEST'
	};
};

export const actionResetsSuccess = (data) => {
	return {
		type: 'ACTION_RESETS_SUCCESS',
		payload: data
	};
};

export const actionResetsFailure = (data) => {
	return {
		type: 'ACTION_RESETS_FAILURE',
		payload: data
	};
};

export const resetActions = () => {
	return {
		type: 'RESET_ACTIONS'
	};
};

/*
** Send Reset Password Link
*/
export const passwordForgot = (params) => {
	const url = config.baseAPIURL + 'forgot';

	return (dispatch) => {
        dispatch(actionForgotRequest());
		axios
			.post(url, params)
			.then((response) => {
				dispatch(actionForgotSuccess(response.data.data));
			})
			.catch((error) => {
				const emsg = error.data || "Unable to process your request. Please contact us via our Help Center.";
                dispatch(actionForgotFailure(emsg));
            });
	};

};

/*
** Reset Password
*/
export const passwordResets = (params) => {
    const url = config.baseAPIURL + 'resets';

	return (dispatch) => {
        dispatch(actionResetsRequest());
		axios
			.post(url, params)
			.then((response) => {
				dispatch(actionResetsSuccess(response.data.data));
			})
			.catch((error) => {
				const emsg = error.data || "Unable to process your request. The link may have expired already. Either request for a new link or contact us via our Help Center.";
                dispatch(actionResetsFailure(emsg));
            });
	};
};