import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import config from '../utils/config';

import CityGuide from './CityGuideItem';
import Message from '../components/messages/DisplayMessage';

const CityGuideList = ({ city }) => {
	const auth = useSelector((state) => state.auth.isAuthenticated);
	const [ guides, setGuides ] = useState(null);
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState('');
	const [ sort, setSort ] = useState('vote');

	useEffect(
		() => {
			if (city) {
				handleFetch(city.id);
			}
		},
		[ city, sort ]
	);

	const handleSort = (e) => {
		setSort(e.target.value);
	};

	/*
	** Fetch List of Guides
	*/
	const handleFetch = (id) => {
		const apiURL = config.baseAPIURL + 'guides/' + id;

		let params = { sortby: sort };

		if (auth) {
			params.user_id = JSON.parse(localStorage.withinPHToken).id;
		}

		setLoading(true);

		axios
			.get(apiURL, { params: params })
			.then((response) => {
				setGuides(response.data);
				setLoading(false);
			})
			.catch((error) => {
				setError('Error Fetching Travel Guides');
				setLoading(false);
			});
	};

	/*
	** Cast Votes
	*/
	const handleVotes = (id, vote) => {
		const userid = JSON.parse(localStorage.withinPHToken).id;
		const tokens = JSON.parse(localStorage.withinPHToken).data;
		const apiURL = config.baseAPIURL + 'vote/guide/' + id;
		const params = { vote: vote, user_id: userid };
		const header = {
			Accept: 'application/json',
			Authorization: `Bearer ${tokens}`
		};

		axios
			.put(apiURL, params, { headers: header })
			.then((response) => {
				toast.success('Vote added successfully.', { autoClose: 2000 });
				handleFetch(city.id);
			})
			.catch((error) => {
				toast.error('Error adding vote. Try again.', { autoClose: 2000 });
			});
	};

	return (
		<React.Fragment>
			<div className="content-title">
				<div className="row no-gutters">
					<div className="col-12 col-sm-6">
						<h6>Blogs &amp; Vlogs</h6>
					</div>
					<div className="col-12 col-sm-6">
						<div className="input-group input-group-sm">
							<label className="input-group-text">Sort By</label>
							<select className="custom-select" value={sort} onChange={handleSort}>
								<option value="vote">Votes</option>
								<option value="date">Dates</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="content-texts">
				<p>
					Below are some of the travel blogs and vlogs about {city.name}, {city.province_name} - these will
					surely inform you more, entertain, and inspire you to visit {city.name}. Help others by casting your
					vote on which of these articles and videos are worth seeing by either up-voting or down-voting.
					Contact us if you want to add more in this list.
				</p>
			</div>

			{/* Errors */}
			{error && !loading && <Message message={error} />}

			{guides &&
				(guides.length > 0 ? (
					<ul className="list-group content-lists content-lists-vote">
						{guides.map((guide) => (
							<li key={guide.id} className="list-group-item d-flex justify-content-between lh-condensed">
								<CityGuide guide={guide} handleVotes={handleVotes} auth={auth} />
							</li>
						))}
					</ul>
				) : (
					!error && <Message message="No Travel Guides Found Yet" />
				))}
		</React.Fragment>
	);
};

export default CityGuideList;
