import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProvinces } from '../redux/actions';

const FilterProvinces = ({ initial, handleChange }) => {
	const dispatch = useDispatch();

	const provinces = useSelector((state) => state.province);
	const [ province, setProvince ] = useState(initial);

	useEffect(() => {
		// fetch list of provinces if empty
		if (!Object.keys(provinces).length) {
			dispatch(fetchProvinces());
		}
	}, []);

	useEffect(
		() => {
			if (province && Object.keys(provinces).length > 0) {
				if (!isValidState(province)) {
					// send empty or blank province if not valid
					const dummyEvent = { target: { id: 'province', value: '' } };
					setProvince('');
					handleChange(dummyEvent);
				}
			}
		},
		[ provinces ]
	);

	const handleSelect = (e) => {
		setProvince(e.target.value);
		handleChange(e);
	};

	const isValidState = (p) => {
		let provinceExist = false;

		// check if the province in url parmeter is a valid province
		Object.keys(provinces).forEach((key) => {
			provinceExist = provinceExist || Object.values(provinces[key]).some((province) => province === p);
		});

		return provinceExist;
	};

	return (
		<select
			className="custom-select"
			id="province"
			name="province"
			value={province}
			onChange={handleSelect}
			disabled={!Object.keys(provinces).length > 0}
		>
			<option value="">Any Province</option>
			{Object.keys(provinces).map((key) => (
				<option key={provinces[key].id} value={provinces[key].slug}>
					{provinces[key].name}
				</option>
			))}
		</select>
	);
};

export default FilterProvinces;
