import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import axios from 'axios';

import { createTrip } from '../../redux/actions';
import config from '../../utils/config';

import Loading from '../loading/BouncingDots';

const TripForm = ({ username, handleAddTrip }) => {
	const dispatch = useDispatch();

	const hasErrors = useSelector((state) => state.trip.createError);
	const isLoading = useSelector((state) => state.trip.actionLoading);

	const [ loading, setLoading ] = useState(false);
	const [ errMess, setErrMess ] = useState('');

	const [ date, setDate ] = useState({ date_start: null, date_end: null });
	const [ city, setCity ] = useState({ id: null, name: null });

	const [ cities, setCities ] = useState([]);
	const [ search, setSearch ] = useState('');
	const [ select, setSelect ] = useState(-1);

	useEffect(
		() => {
			if (hasErrors) {
				setLoading(isLoading);
				setErrMess(hasErrors);
			}
		},
		[ hasErrors, isLoading ]
	);

	useEffect(
		() => {
			if (search.length >= 3) {
				handleSearch(search);
			} else {
				setCities([]);
			}
		},
		[ search ]
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		if (!city.id) {
			setLoading(false);
			setErrMess('City or Municipality is required.');
		} else if (!date.date_start && date.date_end) {
			setLoading(false);
			setErrMess('Start Date is missing.');
		} else if (date.date_start && date.date_end && date.date_start > date.date_end) {
			setLoading(false);
			setErrMess('Provided dates are invalid.');
		} else {
			const data = { ...date, city_id: city.id, username: username };
			dispatch(createTrip(data));
			handleAddTrip();
		}
	};

	// function to trigger api request
	const handleSearch = (search) => {
		const url = config.baseAPIURL + 'search/cities/' + search;

		// fetch search search results
		axios
			.get(url)
			.then((response) => {
				setCities([ ...response.data ]);
			})
			.catch((error) => {});
	};

	// function to clear selected city
	const handleResets = (e) => {
		e.preventDefault();
		setCity({ id: null, name: null });
	};

	// function to capture selected city
	const handleSelect = () => {
		setCity({ id: cities[select].id, name: cities[select].name });
		setSearch('');
		setSelect(-1);
		setCities([]);
	};

	const handleCancel = () => {
		setCity({ id: null, name: null });
		setDate({ date_start: null, date_end: null });

		setSearch('');
		setSelect(-1);
		setCities([]);
	};

	// function to handle key actions on form
	const handleKeyAct = (e) => {
		if (e.keyCode === 13 && select >= 0) {
			// enter key
			handleSelect();
		} else if (e.keyCode === 38 && select >= 0) {
			// up arrow key
			setSelect(select - 1);
		} else if (e.keyCode === 40 && select < cities.length - 1) {
			// down arrow key
			setSelect(select + 1);
		}
	};

	return (
		<form id="TripForm" onSubmit={handleSubmit}>
			{errMess && (
				<div className="alert alert-danger" role="alert">
					{errMess}
				</div>
			)}

			<div className="form-group">
				<label htmlFor="city">City or Municipality</label>

				{city.id ? (
					<div className="d-flex justify-content-between">
						<h6>{city.name}</h6>
						<button className="btn btn-borderless" onClick={handleResets}>
							<i className="fas fa-times-circle" />
						</button>
					</div>
				) : (
					<input
						id="search"
						name="search"
						className="form-control form-control-dark"
						type="text"
						placeholder="Search City or Municipality"
						autoComplete="off"
						onChange={(e) => {
							setSearch(e.target.value);
							setSelect(-1);
						}}
						onKeyDown={handleKeyAct}
						value={search}
						disabled={city.id ? true : false}
					/>
				)}

				{cities.length > 0 ? (
					<ul className="SearchCity">
						{cities.map((item, indx) => (
							<li
								key={indx}
								className={select === indx ? 'active' : null}
								onMouseEnter={() => setSelect(indx)}
								onClick={() => handleSelect()}
							>
								{item.name}
							</li>
						))}
					</ul>
				) : search.length >= 3 ? (
					<ul className="SearchCity">
						<li>City Not Found</li>
					</ul>
				) : (
					''
				)}
			</div>

			<div className="form-group">
				<label className="d-block">Trip Dates (optional)</label>
				<div className="btn-group">
					<DatePicker
						id="date_start"
						name="date_start"
						className="form-control form-control-sm w-100"
						autoComplete="off"
						dateFormat="yyyy/MM/dd"
						maxDate={new Date()}
						scrollableMonthYearDropdown
						placeholderText="start"
						selected={date.date_start}
						onChange={(newdate) => setDate({ ...date, date_start: newdate })}
					/>
					<label className="p-1 px-2">to</label>
					<DatePicker
						id="date_end"
						name="date_end"
						className="form-control form-control-sm w-100"
						autoComplete="off"
						dateFormat="yyyy/MM/dd"
						minDate={date.date_start || null}
						maxDate={new Date()}
						scrollableMonthYearDropdown
						placeholderText="end"
						selected={date.date_end}
						onChange={(newdate) => setDate({ ...date, date_end: newdate })}
					/>
				</div>
			</div>

			<div className="form-group m-0 d-flex justify-content-end">
				<button
					type="button"
					className="btn btn-sm btn-danger mr-1"
					data-dismiss="modal"
					onClick={handleCancel}
				>
					Cancel
				</button>

				{loading ? (
					<button className="btn btn-gray btn-block" type="submit" disabled>
						<Loading />
					</button>
				) : (
					<button className="btn btn-gray btn-block" type="submit">
						Add New Trip
					</button>
				)}
			</div>
		</form>
	);
};

export default TripForm;
