import axios from 'axios';
import config from '../../utils/config';

export const fetchCitiesRequest = () => {
	return {
		type: 'FETCH_CITIES_REQUEST'
	};
};

export const fetchCitiesNxtRqst = () => {
	return {
		type: 'FETCH_CITIES_NXTRQST'
	};
};

export const fetchCitiesSuccess = (data) => {
	return {
		type: 'FETCH_CITIES_SUCCESS',
		payload: data
	};
};

export const fetchCitiesNxtPage = (data) => {
	return {
		type: 'FETCH_CITIES_NXTPAGE',
		payload: data
	};
};

export const fetchCitiesFailure = (data) => {
	return {
		type: 'FETCH_CITIES_FAILURE',
		payload: data
	};
};

/* 
** Fetch New Set of Cities
*/
export const fetchCities = (url = null, parameters = '') => {
	url = url ? url : config.baseAPIURL + 'cities';

	let hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchCitiesRequest());
		axios
			.get(url + parameters, { data: {} }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				data.filter = parameters;
				dispatch(fetchCitiesSuccess(data));
			})
			.catch((error) => {
				const emsg = error.message;
				dispatch(fetchCitiesFailure(emsg));
			});
	};
};

/*
** Fetch Next Page of Cities
*/
export const fetchMoreCities = (url, parameters = '') => {
	url = url ? url : config.baseAPIURL + 'cities';
	let hdr = {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	};

	return (dispatch) => {
		dispatch(fetchCitiesNxtRqst());
		axios
			.get(url, { data: {} }, { headers: hdr })
			.then((response) => {
				const data = response.data;
				data.filter = parameters;
				dispatch(fetchCitiesNxtPage(data));
			})
			.catch((error) => {
				const emsg = error.message;
				dispatch(fetchCitiesFailure(emsg));
			});
	};
};
