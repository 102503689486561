const initState = {
	actionError: '',
	actionLoading: false,
	fetchError: '',
	fetchLoading: false,
	reviews: {}
};

const reviewReducer = (state = initState, action) => {
	switch (action.type) {
		case 'ACTION_REVIEW_REQUEST':
			return {
				...state,
				actionError: '',
				actionLoading: true
			};

		case 'CREATE_REVIEW_SUCCESS':
			return {
				...state,
				actionError: '',
				actionLoading: false
			};

		case 'UPDATE_REVIEW_SUCCESS':
			return {
				...state,
				actionError: '',
				actionLoading: false
			};

		case 'DELETE_REVIEW_SUCCESS':
			return {
				...state,
				actionError: '',
				actionLoading: false
			};

		case 'ACTION_REVIEW_FAILURE':
			return {
				...state,
				actionError: action.payload,
				actionLoading: false
			};
		case 'FETCH_REVIEWS_REQUEST':
			return {
				...state,
				reviews: {},
				fetchError: '',
				fetchLoading: true
			};
		case 'FETCH_REVIEWS_SUCCESS':
			return {
				...state,
				reviews: action.payload,
				fetchError: '',
				fetchLoading: false
			};
		case 'FETCH_REVIEWS_FAILURE':
			return {
				...state,
				reviews: {},
				fetchError: action.payload,
				fetchLoading: false
			};

		default:
			return state;
	}
};

export default reviewReducer;
