import React from 'react';
import EventCard from '../components/EventCard';

const EventsListing = ({ events, auth, handleVotes }) => {

	return (
		<ul className="list-group content-lists content-lists-vote lists-events lists-expandable">
			{events && events.map((event, idx) => <EventCard key={idx} event={event} handleVotes={handleVotes} auth={auth} />)}
		</ul>
	);
};

export default EventsListing;
