import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../utils/config';

export const fetchAuthRequest = () => {
	return {
		type: 'FETCH_AUTH_REQUEST'
	};
};

export const fetchAuthSignout = () => {
	return {
		type: 'FETCH_AUTH_SIGNOUT'
	};
};

export const fetchAuthSuccess = (data) => {
	return {
		type: 'FETCH_AUTH_SUCCESS',
		payload: data
	};
};

export const fetchAuthFailure = (data) => {
	return {
		type: 'FETCH_AUTH_FAILURE',
		payload: data
	};
};

export const updateUserRequest = (code) => {
	return {
		type: 'UPDATE_USER_REQUEST',
		code: code
	};
};

export const updateUserSuccess = (data, code) => {
	return {
		type: 'UPDATE_USER_SUCCESS',
		payload: data,
		code: code
	};
};

export const updateUserFailure = (data, code) => {
	return {
		type: 'UPDATE_USER_FAILURE',
		payload: data,
		code: code
	};
};

/*
** Check User Authentication
** on System Load
*/
export const userVerify = () => {
	let userID = JSON.parse(localStorage.withinPHToken).id;
	let tokens = JSON.parse(localStorage.withinPHToken).data;

	// initialize request url
	let url = config.baseAPIURL + 'users/' + userID;

	// initialize request header
	let hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`
	};

	return (dispatch) => {
		axios
			.get(url, { headers: hdr })
			.then((response) => {
				const data = response.data;
				dispatch(fetchAuthSuccess(data.user));
			})
			.catch((error) => {
				// remove user's token in local storage
				localStorage.removeItem('withinPHToken');
				dispatch(fetchAuthFailure(''));
				window.location.reload(false);
			});
	};
};

/*
** Cancel Authentication
** on Logout
*/
export const userLogout = () => {
	let tokens = JSON.parse(localStorage.withinPHToken).data;

	// initialize request url
	let url = config.baseAPIURL + 'logout';

	// initialize request header
	let hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`
	};

	// remove user's token in local storage
	localStorage.removeItem('withinPHToken');

	return (dispatch) => {
		axios.get(url, { headers: hdr });
		dispatch(fetchAuthSignout());

		// refresh page
		//
		// we need to refresh the page to reload the auth modals need
		// because for some reason it is not working after sign out
		window.location.reload(false);
	};
};

/*
** Fetch Authentication
** on Login
** PARAM = object - login details
*/
export const userSignIn = (param) => {
	let url = config.baseAPIURL + 'login';
	let crd = {
		username: param.username.replace(/<\/?[^>]+(>|$)/g, ''),
		password: param.password
	};

	return (dispatch) => {
		dispatch(fetchAuthRequest());

		axios
			.post(url, crd)
			.then((response) => {
				const data = response.data;
				const auth = {
					data: data.user.token,
					id: data.user.id,
					expiration: data.user.expires_at
				};

				// save token to client storage
				localStorage.setItem('withinPHToken', JSON.stringify(auth));

				dispatch(fetchAuthSuccess(data.user));

				// hide modals
				$('.modal.in').modal('hide');
				$('.modal-backdrop').remove();

				window.location.reload(false);
			})
			.catch((error) => {
				const emsg = 'Invalid login credentials';
				dispatch(fetchAuthFailure(emsg));
			});
	};
};

/*
** Create New User
** on SignUp
** PARAM = object - registration details
*/
export const userSignUp = (param) => {
	let url = config.baseAPIURL + 'register';
	let crd = {
		username: param.username.replace(/<\/?[^>]+(>|$)/g, ''),
		email: param.email.replace(/<\/?[^>]+(>|$)/g, ''),
		password: param.password,
		password_confirmation: param.password
	};

	return (dispatch) => {
		dispatch(fetchAuthRequest());
		axios
			.post(url, crd)
			.then((response) => {
				const data = response.data;
				const auth = {
					data: data.user.token,
					id: data.user.id,
					expiration: data.user.expires_at
				};

				// save token to client storage
				localStorage.setItem('withinPHToken', JSON.stringify(auth));

				dispatch(fetchAuthSuccess(data.user));

				// hide modals
				$('.modal.in').modal('hide');
				$('.modal-backdrop').remove();

				window.location.reload(false);
			})
			.catch((error) => {
				let serverError = error.response.data.message[0];
				const emsg =
					serverError !== undefined ? serverError[0].text : 'Error creating new account. Please try again!';
				dispatch(fetchAuthFailure(emsg));
			});
	};
};

/*
** Update User Profile
** or Account or Password
*/
export const updateUser = (param, code) => {
	let userID = JSON.parse(localStorage.withinPHToken).id;
	let tokens = JSON.parse(localStorage.withinPHToken).data;

	// initialize url
	let url = config.baseAPIURL + 'users/' + code + '/' + userID;

	// initialize request header
	let hdr = {
		Accept: 'application/json',
		Authorization: `Bearer ${tokens}`
	};

	let formdata;
	let method = 'put';

	switch (code) {
		case 'profile':
			method = 'post';
			formdata = new FormData();
			formdata.append('firstname', param.firstname);
			formdata.append('lastname', param.lastname);
			formdata.append('gender', param.gender);
			formdata.append('biography', param.biography);
			formdata.append('website', param.website);
			if (param.image) {
				formdata.append('image', param.image, param.image.name);
			}
			if (param.birth_date) {
				formdata.append('birth_date', param.birth_date);
			}
			break;
		case 'password':
			formdata = { password: param.password };
			break;
		case 'account':
			formdata = { email: param.email };
			break;
		case 'delete':
			formdata = {};
			break;
		default:
			break;
	}

	return (dispatch) => {
		dispatch(updateUserRequest(code));
		axios({
			method: method,
			url: url,
			data: formdata,
			headers: hdr
		})
			.then((response) => {
				const data = response.data;
				dispatch(updateUserSuccess(data.user, code));
				if (code === 'delete' || code === 'password') {
					// remove user's token in local storage
					localStorage.removeItem('withinPHToken');
					window.location.reload(false);
				} else {
					toast.success(`Your ${code} has been updated successfully!`);
					window.scrollBy({
						top: -2500,
						left: 0,
						behavior: 'smooth'
					});
				}
			})
			.catch((error) => {
				const emsg = error.message || `Failed to update yor ${code}. Please try again.`;
				dispatch(updateUserFailure(emsg, code));
			});
	};
};
