import React, { Component } from 'react';

import MetaTag from '../components/MetaTags';
import Message from '../components/messages/DisplayMessage';

class NotFound extends Component {
	render() {

		return (
			<main className="middle container-fluid">
				<MetaTag follow={false} />
				<Message message="Uh-oh! Looks like you're lost." />
				<Message
					message="It's okay to get lost every once in a while. Why don't you go back home or contact us for help."
					buttons={true}
				/>
			</main>
		);
	}
}

export default NotFound;
