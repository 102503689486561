import React, { Component } from 'react';

import CardCity from '../components/CardCity';
import CardLink from '../components/CardLink';

class CitiesListing extends Component {
	render() {
		const { cities } = this.props;

		return (
			<section className="middle-card-sector">
				<div className="row">
					{cities &&
						cities.map(
							(city, idx) =>
								idx === 3 ? (
									<React.Fragment key="Fragment">
										<CardLink key={`${idx}-INFO`} />
										<CardCity key={idx} city={city} />
									</React.Fragment>
								) : (
									<CardCity key={idx} city={city} />
								)
						)}
				</div>
			</section>
		);
	}
}

export default CitiesListing;
