import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReviews, deleteReview } from '../redux/actions';

import Message from '../components/messages/DisplayMessage';
import Loading from '../components/loading/BouncingDots';
import ConfirmDelete from '../components/modals/ConfirmDelete';

import Review from './CityReviewItem';
import Pagination from '../components/Pagination';

const CityReviewList = ({ city, isAuthenticated }) => {
	const dispatch = useDispatch();

	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState('');
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ reviewsPerPage ] = useState(3);
	const [ reviewToDelete, setReviewToDelete ] = useState(null);

	const user = useSelector((state) => state.auth.user);
	const reviews = useSelector((state) => state.review.reviews);
	const fetchLoading = useSelector((state) => state.review.fetchLoading);
	const fetchError = useSelector((state) => state.review.fetchError);

	const idxOfLastReview = currentPage * reviewsPerPage;
	const idxOfFrstReview = idxOfLastReview - reviewsPerPage;
	const curSetofReviews = reviews.length > 0 ? reviews.slice(idxOfFrstReview, idxOfLastReview) : reviews;

	useEffect(() => {
		// fetch city reviews
		dispatch(fetchReviews(city.id));
	}, []);

	useEffect(
		() => {
			setLoading(fetchLoading);
			if (fetchError) {
				setError(fetchError);
			}
		},
		[ fetchLoading, fetchError ]
	);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleClick = (id) => {
		setReviewToDelete(id);
		$('#DeleteModal').modal('show');
	};

	const handleDelete = () => {
		const params = { review_id: reviewToDelete, city_id: city.id, city_name: city.name };
		dispatch(deleteReview(params));
		setCurrentPage(1);
		$('#DeleteModal').modal('hide');
	};

	return (
		<div>
			<div className="content-title">
				<div className="row no-gutters">
					<div className="col-12 col-sm-6">
						<h6>Reviews ({reviews.length})</h6>
					</div>
					<div className="col-12 col-sm-6 d-flex justify-content-center d-sm-inline">
						{isAuthenticated ? user.is_verified ? (
							<Link
								to={`/review/${city.province_slug}/${city.slug}`}
								className="btn btn-sm btn-gray d-block d-sm-inline"
							>
								<i className="fas fa-star" /> Write a Review
							</Link>
						) : (
							<button
								className="btn btn-sm btn-gray d-block d-sm-inline"
								data-toggle="modal"
								data-target="#VerifyEmail"
							>
								<i className="fas fa-star" /> Write a Review
							</button>
						) : (
							<button
								className="btn btn-sm btn-gray d-block d-sm-inline"
								data-toggle="modal"
								data-target="#signInModal"
							>
								<i className="fas fa-star" /> Write a Review
							</button>
						)}
					</div>
				</div>
			</div>

			<div className="content-texts">
				<p>
					Check out what other travellers are saying about {city.name}, {city.province_name} Philippines. Have
					you visited {city.name} already? Start sharing your thoughts about it! You can leave your own review
					and rating about it to help other travellers too. It's easy to do just click the using the 'Write a
					Review' button above.
				</p>
			</div>

			{/* Loader */}
			{loading && <Loading />}

			{/* Errors */}
			{error && !loading && <Message message={error} />}

			{/* Detail */}
			{reviews.length > 0 ? (
				<React.Fragment>
					{curSetofReviews.map((review, index) => (
						<div key={review.id} className="list-group content-lists content-lists-comment">
							<Review review={review} user={user} handleDelete={handleClick} />
						</div>
					))}
					<Pagination itemsPerPage={reviewsPerPage} totalItems={reviews.length} paginate={paginate} />
				</React.Fragment>
			) : (
				!error && <Message message="No Reviews Yet" />
			)}

			{isAuthenticated && <ConfirmDelete id="DeleteModal" type="review" handleDelete={handleDelete} />}
		</div>
	);
};

export default CityReviewList;
