import React, { Component } from 'react';

class BouncingDots extends Component {
	render() {
		return (
			<div className="spinner bouncingdots">
				<div className="bounce1" />
				<div className="bounce2" />
				<div className="bounce3" />
				{this.props.text && <span>{this.props.text}</span>}
			</div>
		);
	}
}

export default BouncingDots;
