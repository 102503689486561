import React from 'react';
import moment from 'moment';

import { attachUTMCodeOnLink } from '../utils/helpers';
import config from '../utils/config';
import DefaultImage from '../assets/images/item.png';

const CityGuide = ({ guide, handleVotes, auth }) => {
	const handleClick = (e) => {
		handleVotes(guide.id, e.target.value);
	};

	const vote = guide.votes && guide.votes.length ? Boolean(guide.votes[0].vote) : null;

	return (
		<React.Fragment>
			<div className="media">
				<img
					className="item-middle-list-img"
					src={guide.image ? config.baseIMGURL + guide.image : DefaultImage}
					alt={guide.title}
				/>

				<div className="media-body item-middle-list-txt">
					<a href={attachUTMCodeOnLink(guide.link)} target="_blank" rel="nofollow noopener noreferrer">
						{guide.type === 'vlog' ? (
							<i className="fas fa-video mr-1" />
						) : (
							<i className="fas fa-sticky-note mr-1" />
						)}
						{guide.title}
					</a>
					<span className="list-group-taxonomy">
						{guide.date ? moment(guide.date).calendar(null, { sameElse: 'DD MMMM, YYYY' }) : 'Date Unknown'}
					</span>
					<span className="list-group-descript">{guide.text}</span>
				</div>

				<div className="content-lists-vote-btn">
					<div className="list-vote-love">
						{auth ? (
							<button
								value="1"
								className={vote !== null && vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleClick}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
					<div className="list-vote-text">{guide.total_votes}</div>
					<div className="list-vote-hate">
						{auth ? (
							<button
								value="0"
								className={vote !== null && !vote ? 'btn btn-sm active' : 'btn btn-sm'}
								onClick={handleClick}
							/>
						) : (
							<button className="btn btn-sm" data-toggle="modal" data-target="#signInModal" />
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CityGuide;
