import React, { useState } from 'react';
import moment from 'moment';

const FilterMonths = ({ initial, name, handleChange }) => {
	const [ filter, setFilter ] = useState(initial);

	const handleSelect = (e) => {
		setFilter(e.target.value);
		handleChange(e);
	};

	return (
		<select className="custom-select" id={name} name={name} value={filter} onChange={handleSelect}>
			<option value="">Any Month</option>
			{[ ...Array(12) ].map((content, index) => (
				<option key={moment().month(index).format('MMMM')} value={moment().month(index).format('MMMM')}>
					{moment().month(index).format('MMMM')}
				</option>
			))}
		</select>
	);
};

export default FilterMonths;
