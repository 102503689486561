import React, { useState } from 'react';
import axios from 'axios';

import config from '../../utils/config';
import Ellipsis from '../loading/LoadingEllipsis';

const VerificationLink = ({ user, handleUpdate }) => {
	const handleReSend = () => {
		const userID = JSON.parse(localStorage.withinPHToken).id;
		const tokens = JSON.parse(localStorage.withinPHToken).data;

		// initialize request url
		const url = config.baseAPIURL + 'email/resend/' + userID;

		// initialize request header
		const hdr = {
			Accept: 'application/json',
			Authorization: `Bearer ${tokens}`
		};

		setVerification({ loading: true, error: '', sent: false });
		axios
			.get(url, { headers: hdr })
			.then((response) => {
				setVerification({ loading: false, error: '', sent: true });
			})
			.catch((error) => {
				const emsg = error.message || 'Failed to resend your link. Please refresh page and try again.';
				setVerification({ loading: false, error: emsg, sent: false });
			});
	};

	const [ verification, setVerification ] = useState({ loading: false, error: '', sent: false });

	return (
		<React.Fragment>
			{verification.loading ? (
				<React.Fragment>
					<span>Sending</span>
					<Ellipsis />
				</React.Fragment>
			) : verification.error ? (
				<span className="text-error">{verification.error}</span>
			) : verification.sent ? (
				<span>
					<i className="text-success fas fa-check mr-1" /> Verification Sent. Please check you email!
				</span>
			) : (
				<button to="/" className="btn btn-xs btn-borderless btn-verification" onClick={handleReSend}>
					Resend Verification Link
				</button>
			)}
		</React.Fragment>
	);
};

export default VerificationLink;
