/*
** Convert Score Rating
** to Score Text and Class
**  
** Parameter: Integer Rating
** Return: Object Score Attributes
*/
export function getScoreDisplays(rate) {
	let score = { text: '', clss: '' };

	switch (true) {
		case rate > 90:
			score.text = 'Great';
			score.clss = 'bg-success';
			break;
		case rate > 80 && rate <= 90:
			score.text = 'Good';
			score.clss = 'bg-success';
			break;
		case rate > 40 && rate <= 80:
			score.text = 'Okay';
			score.clss = 'bg-warning';
			break;
		case rate > 10 && rate <= 40:
			score.text = 'Bad';
			score.clss = 'bg-danger';
			break;
		case rate > 0 && rate <= 10:
			score.text = 'Terrible';
			score.clss = 'bg-danger';
			break;
		default:
			score.text = 'No Data Yet';
			score.clss = '';
			break;
	}

	return score;
}
